import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleWithIcon } from 'features/common/components/CircleWithIcon';
import { Icon } from 'features/common/components/Icon';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { common } from 'theme';
import { AffiliateProps } from './types';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { ModalDelete } from 'features/common/components/ModalDelete';
import { useDeleteAffiliate } from '../hooks/useDeleteAfiiliate';
import { useResponsiveScreen } from 'hooks/useWindowSize';

import { StdText } from 'features/common/components/Text/Text.styles';
import { Column, Row, HighlightText, BoldText, MaxColumnFlex, Link, ICON_WIDTH } from './Affiliate.styles';

export const Affiliate = ({ eventId, data, queryKey }: AffiliateProps) => {
	const [showModal, setShowModal] = useState(false);
	const link = `${process.env.REACT_APP_WEBSITE_URL}/${eventId}?affiliate=${data.code}`;

	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const copyToClipboard = useCopyToClipboard();
	const modalInfo = useDeleteAffiliate({ affiliateId: data.id, queryKey, setShowModal });

	return (
		<Row>
			{isLargeScreen ? (
				<>
					<FlexContainer alignItems="center" gap="24px">
						<CircleWithIcon
							className="hover"
							onClick={() => copyToClipboard(link, t('dashboard-affiliate-link'))}
							color={common.colors.grey11}
							height="45px"
							width="45px"
							iconName="link"
						/>
						<MaxColumnFlex>
							<BoldText>{data.full_name}</BoldText>
							<Link href={link} target="_blank">
								{link}
							</Link>
						</MaxColumnFlex>
					</FlexContainer>
					<Column isPaired>
						<StdText>{t('dashboard-affiliate-revenue')}</StdText>
						<HighlightText> ${data.revenue}</HighlightText>
					</Column>
					<Column isPaired>
						<StdText>{t('dashboard-affiliate-tixSold')}</StdText>
						<HighlightText> {data.quantity}</HighlightText>
					</Column>
					<Icon iconName="trash" onClick={() => setShowModal(true)} />
				</>
			) : (
				<>
					<FlexContainer flexDirection="column" alignItems="center" gap="24px">
						<CircleWithIcon
							className="hover"
							onClick={() => copyToClipboard(link, t('dashboard-affiliate-link'))}
							color={common.colors.grey11}
							height="45px"
							width={ICON_WIDTH}
							iconName="link"
						/>
						<CircleWithIcon
							className="hover"
							onClick={() => setShowModal(true)}
							color={common.colors.grey11}
							height="45px"
							width={ICON_WIDTH}
							iconName="trash"
						/>
					</FlexContainer>
					<Column isLimited>
						<MaxColumnFlex>
							<BoldText>{data.full_name}</BoldText>
							<Link href={link} target="_blank">
								{link}
							</Link>
						</MaxColumnFlex>
						<Column isPaired>
							<StdText>{t('dashboard-affiliate-revenue')}</StdText>
							<HighlightText> ${data.revenue}</HighlightText>
						</Column>
						<Column isPaired>
							<StdText>{t('dashboard-affiliate-tixSold')}</StdText>
							<HighlightText> {data.quantity}</HighlightText>
						</Column>
					</Column>
				</>
			)}
			<ModalDelete modalInfo={modalInfo} showModal={showModal} closeModal={() => setShowModal(false)} />
		</Row>
	);
};
