import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Form } from 'features/common/components/Form';
import { ImgWithHover } from 'features/common/components/ImageInputs';
import { InputLabel } from 'features/common/components/Form/InputLabel';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { EventSchedule } from 'features/common/components/EventSchedule';

export const KeyDetails = () => {
	const { t } = useTranslation();
	const { watch } = useFormContext();
	const address = watch('location');

	return (
		<>
			<Form.Input
				id="name"
				label={t('createEvent-eventSummary-name')}
				name="name"
				placeholder={t('createEvent-name-placeholder')}
				autoComplete="off"
			/>
			<Form.InputText
				id="description"
				name="description"
				label={t('createEvent-eventSummary-description')}
				placeholder={t('createEvent-description-placeholder')}
			/>
			<Form.AddressAutocomplete
				id="location"
				name="location"
				label={t('createEvent-eventSummary-location')}
				value={address}
			/>
			<Form.Input
				autoComplete="off"
				id="postal_code"
				label={t('createEvent-zip-code')}
				name="postal_code"
				placeholder={t('createEvent-zip-code-placeholder')}
				info={t('only-usa')}
			/>
			<FlexContainer flexDirection="column" width="100%">
				<InputLabel htmlFor="images" label={t('generic-event-image')} />
				<ImgWithHover inputId="images" />
			</FlexContainer>
			<EventSchedule isEventCreation />
			<Form.InputDateAndTime id="sale_date" name="sale_date" label={t('createEvent-eventSummary-saleDate')} />
			<Form.TimeZone />
		</>
	);
};
