import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { Divider } from 'features/common/components/Divider';
import { ROUTES } from 'features/routes';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { TicketOrder, TicketTypeProps } from './types';
import { IdParamsType } from 'utils/types';
import { AddTicketCard } from './TicketCard/AddTicketCard';
import { MultiButtonSectionTitle } from 'features/common/components/MultiButtonSectionTitle';
import { setTicketsAvailable } from 'store/slices/BoxOfficeSlice';
import { useAppDispatch } from 'store/hooks';
import { useToastNotification } from 'hooks/useToastNotification';
import { TYPES } from 'hooks/useToastNotification/constants';
import { TicketTypesList } from './TicketTypesList/TicketTypesList';

import { TicketsContainer } from './TicketsSection.styles';
import { reorderTicketTypesPut } from './services';
import { useTicketTypesApi } from './hooks';
import { sortTicketTypes } from './utils';

const TicketsSection: FC = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const dispatch = useAppDispatch();

	const isLargeScreen = useResponsiveScreen();
	const { showNotification } = useToastNotification();
	const { id } = useParams<IdParamsType>();

	const [ticketTypes, setTicketTypes] = useState<TicketTypeProps[]>([]);
	const [ticketsOrder, setTicketsOrder] = useState<TicketOrder[]>([]);
	const [showSaveButtons, setShowSaveButtons] = useState(false);

	const { ticketTypes: ticketTypesData } = useTicketTypesApi();

	useEffect(() => {
		if (ticketTypesData) {
			setTicketTypes(sortTicketTypes(ticketTypesData));
			setTicketsOrder(ticketTypesData.map(({ iD, orderIndex }) => ({ ID: iD, orderIndex })));
			dispatch(setTicketsAvailable(ticketTypesData.length > 0));
		}
	}, [JSON.stringify(ticketTypesData)]);

	const onClickAddNew = () => push(ROUTES.TICKET_NEW_PATH.replace(':id', id));
	const onSendTicket = () => push(ROUTES.SEND_TICKETS.replace(':id', id));

	const onSave = async () => {
		try {
			await reorderTicketTypesPut(ticketTypes);
			setShowSaveButtons(false);
			setTicketsOrder(ticketTypes.map(({ iD, orderIndex }) => ({ ID: iD, orderIndex })));
			showNotification({ message: t('dashboard-ticketTypes-reorder-success'), type: TYPES.SUCCESS });
		} catch (error) {
			showNotification({ message: t('dashboard-ticketTypes-reorder-error'), type: TYPES.ERROR });
		}
	};

	const onRevert = () => {
		const newTicketTypes = ticketTypes.map((tt) => ({
			...tt,
			orderIndex: ticketsOrder.find(({ ID }) => ID === tt.iD)?.orderIndex ?? tt.orderIndex,
		}));

		setTicketTypes(sortTicketTypes(newTicketTypes));
		setShowSaveButtons(false);
	};

	const buttons = [
		{ label: t('dashboard-reorder'), onClick: () => setShowSaveButtons(true) },
		{ label: t('compedTickets-send'), onClick: onSendTicket },
		{ label: t('dashboard-add'), onClick: onClickAddNew },
	];

	const saveButtons = [
		{ label: t('dashboard-ticketTypes-reorder-revert'), onClick: onRevert },
		{ label: t('dashboard-ticketTypes-reorder-save'), onClick: onSave, category: 'primary' },
	];

	return (
		<>
			<MultiButtonSectionTitle title={t('dashboard-tickets')} buttons={showSaveButtons ? saveButtons : buttons} />

			<TicketsContainer>
				{ticketTypes?.length > 0 ? (
					<TicketTypesList
						ticketTypes={ticketTypes}
						setTicketTypes={setTicketTypes}
						showSaveButtons={showSaveButtons}
					/>
				) : (
					<AddTicketCard onClick={onClickAddNew} />
				)}
			</TicketsContainer>

			{!isLargeScreen && <Divider />}
		</>
	);
};

export { TicketsSection };
