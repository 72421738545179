import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Button } from 'features/common/components/Button';

export const HeaderWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-right: 100px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-right: 20px;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 15px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0;
	}
`;

export const Title = styled(Text)`
	color: ${({ theme }) => theme.colors.white};
	font-size: 32px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
	}
`;

export const Subtitle = styled(Text)`
	color: ${({ theme }) => theme.colors.white};
	font-size: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 12px;
		line-height: 16px;
	}
`;

export const ActionButtonsContainer = styled.div`
	display: flex;
	width: 100%;
	border-top: 0.5px solid ${({ theme }) => theme.colors.white2};
	background: ${({ theme }) => theme.colors.blackGrey};
	justify-content: center;
	gap: 32px;
	position: absolute;
	bottom: 0;
	height: 100px;
	align-items: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 8px;
		bottom: 0;
		border: none;
		position: fixed;
	}
`;

export const ActionButton = styled(Button)`
	width: fit-content;
	min-width: 300px;
	position: relative;
	bottom: 0;
	margin: 0;
	height: 56px;
	font-size: 18px;
	padding: unset;

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: 150px;
	}
`;

export const ContainersWrapper = styled.div`
	width: 100%;
	height: calc(100vh - 168px - 100px);

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		overflow: scroll;
		height: calc(100vh - 100px - 128px);
	}
`;

export const DragDropContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px 0;
	box-sizing: border-box;
	overflow: scroll;
	align-items: center;
`;

export const DraggableContainer = styled.div`
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3px;
	overflow: auto;
	width: 100%;
	min-height: 80px;
	min-width: 600px;
	max-width: 60%;
`;

export const FieldContainer = styled.div`
	margin: auto;
	display: flex;
	align-items: stretch;
	height: 100%;
	width: 100%;
	background: ${({ theme }) => theme.colors.whitePerl};

	${DraggableContainer}:not(.dragging-over) & {
		&:first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&:last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
`;

export const InputContainer = styled.div`
	min-height: 48px;
	width: 100%;
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 15px 10px;
	box-sizing: border-box;
`;

export const CustomFieldButtonsContainer = styled.div`
	padding: 0 15px;
	display: flex;
	align-items: center;
`;

export const StyledInput = styled.input`
	all: unset;
	width: 100%;
	border-radius: 4px;
	background: ${({ theme }) => theme.colors.white};
	padding: 8px 16px;
	font-family: ${({ theme }) => theme.fonts.primary};
	box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
	margin: auto;
	display: flex;
	justify-content: center;
	gap: 8px;
	margin-top: 20px;
	min-width: 600px;
	max-width: 40%;
`;

export const SubmitButton = styled(Button)`
	margin: 0;
	display: flex;
	height: 45px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	font-weight: 500;
	align-items: center;
	justify-content: center;
`;

export const PencilButton = styled.button`
	all: unset;
	cursor: pointer;
	height: 25px;
`;
