import { FC } from 'react';
import moment from 'moment';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { convertUTCToDate } from 'utils/utils';
import { Icon } from 'features/common/components/Icon';
import { Table } from 'features/common/components/Table';
import { GRID_COLUMNS, COLUMN_WIDTH } from '../../constants';
import { TicketTableBodyProps } from './types';
import { TicketData } from 'services/FanTickets/types';

import { CheckboxButton } from 'features/common/components/CheckboxButton/CheckBoxButton.styles';

export const TicketTableBody: FC<TicketTableBodyProps> = ({
	ticketData,
	selectedTicketsState: [selectedTickets, setSelectedTickets],
}) => {
	const toggleCheck = (isChecked: boolean, ticketId: number) =>
		setSelectedTickets((prev) => {
			const newState = new Set(prev);
			isChecked ? newState.delete(ticketId) : newState.add(ticketId);
			return newState;
		});

	return (
		<tbody>
			{ticketData?.pages.map((page) =>
				page.refundable_tickets.map((ticket: TicketData) => {
					const purchaseDate = convertUTCToDate(`${ticket.date_of_purchase}`, ticket.event_time_zone);
					const startDate = convertUTCToDate(`${ticket.start_date}`, ticket.event_time_zone);
					const COLUMNS = [
						ticket.event,
						`${moment(startDate).format('L')} | ${moment(startDate).format('LT')}`,
						ticket.ticket_type,
						ticket.ticket_holder,
						`$${ticket.price_paid}`,
						moment(convertUTCToDate(`${purchaseDate}`, ticket.event_time_zone)).format('LL'),
						ticket.notes,
					];
					const isRefunded = ticket.ticket_status === 'refunded';
					const isChecked = selectedTickets.has(ticket.ticket_id);
					return (
						<Table.DataRow
							gridColumns={GRID_COLUMNS}
							key={ticket.ticket_id}
							{...(!isRefunded && { onClick: () => toggleCheck(isChecked, ticket.ticket_id) })}
							className={isChecked ? 'selected' : ''}
						>
							<Table.Cell minWidth={COLUMN_WIDTH[0]}>
								<FlexContainer justifyContent="center" alignItems="center">
									{isRefunded ? (
										<Icon iconName="refundGreen" />
									) : (
										<CheckboxButton type="checkbox" btnSize="18px" checked={isChecked} readOnly />
									)}
								</FlexContainer>
							</Table.Cell>

							{COLUMNS.map((data, i) => (
								<Table.Cell minWidth={COLUMN_WIDTH[i + 1]} key={String(data)}>
									{data}
								</Table.Cell>
							))}
						</Table.DataRow>
					);
				})
			)}
		</tbody>
	);
};
