import { DropResult } from '@hello-pangea/dnd';

import { CustomField } from 'features/CustomFields/types';
import { InputLabel } from 'features/common/components/Form/InputLabel';
import { StyledInput } from './Reorder.styles';

export const reorder = (list: number[], startIndex: number, endIndex: number) => {
	const result = Array.from(list).sort((a, b) => a - b);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const transformData = (customFieldsData: CustomField[]) => {
	// Extract existing rows from custom fields
	let rows: number[] = [];
	customFieldsData.forEach((field) => field.row && rows.push(field.row));

	// Remove duplicates and sort
	rows = Array.from(new Set(rows)).sort((a, b) => a - b);

	// Find the highest row number
	const maxRow = rows.length > 0 ? Math.max(...rows) + 1 : 0;

	// Create rows filling in the gaps
	rows = Array.from({ length: maxRow || customFieldsData.length }, (_, i) => i);

	// See if there are any rows with no fields
	const emptyRows = rows.filter((row) => !customFieldsData.some((field) => field.row === row));

	// Add custom fields with no row to the first empty row
	const newCustomFields = customFieldsData.map((field) => {
		let row = field.row;
		if (row === null) {
			if (emptyRows.length === 0) {
				emptyRows.push(rows.length);
				rows.push(rows.length);
			}
			row = emptyRows.shift() || 0;
		}

		return { ...field, row };
	});

	return { rows, newCustomFields };
};

export const onDragEnd = (result: DropResult, customFields: CustomField[], rows: number[]): CustomField[] => {
	const { source, destination } = result;
	if (!destination) {
		return customFields;
	}

	const newCustomFields: CustomField[] = [];
	reorder(rows, source.index, destination.index).map((row, index) => {
		const customField = customFields.find((cf) => cf.row === row);
		if (customField) {
			newCustomFields.push({ ...customField, row: index });
		}
	});

	return newCustomFields;
};

export const getField = ({ ID, name, type }: CustomField) => {
	switch (type) {
		// TODO: Add more cases
		default:
			return (
				<>
					<InputLabel htmlFor={ID.toString()} label={name} color="black" />
					<StyledInput id={ID.toString()} placeholder={name} />
				</>
			);
	}
};
