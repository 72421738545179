import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
// import { useMutation } from '@tanstack/react-query';

import { Form } from 'features/common/components/Form';
import { Footer } from 'features/common/components/Footer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { MainLayout } from 'layouts/MainLayout';
import { LeftForm } from './components/LeftForm';
import { RightForm } from './components/RightForm';
import { EventTitle } from './components/EventTitle';
import { defaultValues, handleInitialValues, validationSchema } from './utils';
import { IdParamsType, StateProps } from 'utils/types';
import { useEditEventApi } from './hooks';
// import { getData } from 'services/api';

import { ContentWrapper, LeftContainer, RightContainer } from './EditEvent.styles';

const EditEvent: FC = () => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { id }: IdParamsType = useParams();
	const { state: { isFromRequiredFields } = { isFromRequiredFields: false } }: StateProps = useLocation();

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema(t)),
	});
	const { event, editEvent } = useEditEventApi();

	// const { mutate: verifyZip } = useMutation({
	// 	mutationFn: () => getData(`/public/ticketing/avalara-confirmation/${formMethods.getValues('postal_code')}`),
	// 	onSuccess: () => editEvent({ values: formMethods.getValues(), id }),
	// 	onError: () => {
	// 		formMethods.setError('postal_code', {
	// 			type: 'manual',
	// 			message: t('createEvent-zip-code-error'),
	// 		});
	// 		formMethods.setFocus('postal_code');
	// 	},
	// });

	const onEditEvent = (values: any) => {
		editEvent({ values, id });
	};

	useMemo(() => {
		if (event) {
			const allValues = handleInitialValues(event);
			formMethods.reset(allValues);
		}
	}, [event]);

	return (
		<Form formMethods={formMethods} onSubmit={onEditEvent}>
			{!!event && (
				<MainLayout>
					<EventTitle title={event?.name} />
					<ContentWrapper>
						<LeftContainer>
							<LeftForm isFromRequiredFields={isFromRequiredFields} />
						</LeftContainer>
						<RightContainer>
							<RightForm />
						</RightContainer>
					</ContentWrapper>
					<Footer>
						<Footer.Button type="button" onClick={goBack} category={OUTLINED} text={t('editEvent-cancel')} />
						<Footer.Button text={t('editEvent-save')} type="submit" />
					</Footer>
				</MainLayout>
			)}
		</Form>
	);
};

export { EditEvent };
