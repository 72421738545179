import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Form } from 'features/common/components/Form';
import { Footer } from 'features/common/components/Footer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { createTicket } from 'features/CreateTicket/services';
import { ROUTES } from 'features/routes';
import { EventProps } from 'features/Home/components/Events/types';
import { TicketTitle } from './components/TicketTitle';
import { LeftForm } from './components/LeftForm';
import { RightForm } from './components/RightForm';
import { uploadImage } from 'services/UploadImage/uploadImage';
import { getData } from 'services/api';
import { MainLayout } from 'layouts/MainLayout';
import { NEW_TICKET, createBody, validationSchema, defaultValues, fillForm } from './utils';
import { getBase64, sendGAEvent } from 'utils/utils';
import { StateProps } from 'utils/types';
import { CreateTicketForm, TicketBody, TicketDetailsParams } from './types';
import { updateTicket } from './services';
import { useToastNotification } from 'hooks/useToastNotification';

import * as Styled from './TicketDetails.styles';

const TicketDetails: React.FC = () => {
	const { goBack, push } = useHistory();
	const { t } = useTranslation();
	const { id, ticketId } = useParams<TicketDetailsParams>();
	const location = useLocation();
	const { showNotification, TYPES } = useToastNotification();

	const isNewTicket = location.pathname.includes(NEW_TICKET);

	const { state: { isFromRequiredFields } = { isFromRequiredFields: false } }: StateProps = useLocation();

	const { data: ticket } = useQuery({
		queryKey: ['ticket', ticketId],
		queryFn: (): Promise<TicketBody> => getData(`/public/ticketing/ticket_types/${ticketId}`),
		enabled: !isNewTicket,
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema(t)),
	});

	const { data: event } = useQuery({
		queryKey: ['event', id],
		queryFn: (): Promise<EventProps> => getData(`public/ticketing/events/${id}`),
	});

	useEffect(() => {
		if (ticket && event) formMethods.reset(fillForm(ticket, event?.time_zone));
	}, [ticket, event]);

	const { mutateAsync: mutateUploadImage } = useMutation(uploadImage, {
		onSuccess: ({ data: { image_url } }) => {
			formMethods.setValue('image', image_url);
		},
		onError: () => {
			showNotification({
				message: t('error-uploading-image'),
				type: TYPES.ERROR,
			});
		},
	});

	const { mutate: mutateCreateTicket } = useMutation(createTicket, {
		onSuccess: () => {
			sendGAEvent({ category: 'Journeys', action: 'Create ticket' });
			push(ROUTES.DASHBOARD_PATH.replace(':id', id));
		},
	});

	const { mutate: mutateUpdateTicket } = useMutation(updateTicket, {
		onSuccess: () => {
			sendGAEvent({ category: 'Journeys', action: 'Edit ticket' });
			push(ROUTES.DASHBOARD_PATH.replace(':id', id));
		},
	});

	const onSubmitTicketForm = async (currentValues: CreateTicketForm) => {
		const ticketFile = currentValues.ticketImage;

		try {
			if (ticketFile instanceof Blob) {
				const base64TicketImage = await getBase64(ticketFile);
				if (base64TicketImage) {
					const ticketImage = {
						url: '',
						base64: base64TicketImage.toString(),
						name: `${id}-${currentValues.name}`,
					};
					await mutateUploadImage({
						image: ticketImage,
						prefix: `${id}-${currentValues.name}`,
						index: 0,
						type: 'template',
					});
				}
			}

			if (isNewTicket) mutateCreateTicket(createBody(formMethods.getValues(), Number(id), event?.time_zone || ''));
			else mutateUpdateTicket(createBody(formMethods.getValues(), Number(id), event?.time_zone || '', ticket?.ID));
		} catch (error) {
			throw new Error(`Error ${error}`);
		}
	};

	return (
		<Form formMethods={formMethods} onSubmit={onSubmitTicketForm}>
			<MainLayout>
				<TicketTitle isNewTicket={isNewTicket} />
				<Styled.ContentWrapper>
					<Styled.LeftContainer>
						<LeftForm />
					</Styled.LeftContainer>
					<Styled.RightContainer>
						<RightForm isFromRequiredFields={isFromRequiredFields} ticket={ticket} eventId={id} />
					</Styled.RightContainer>
				</Styled.ContentWrapper>
				<Footer>
					<Footer.Button type="button" onClick={goBack} category={OUTLINED} text={t('ticketDetails-cancel')} />
					<Footer.Button
						text={t(`ticketDetails-${isNewTicket ? 'create' : 'save'}`)}
						type="submit"
						data-cy="save-ticket"
					/>
				</Footer>
			</MainLayout>
		</Form>
	);
};

export { TicketDetails };
