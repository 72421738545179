import { useTranslation } from 'react-i18next';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer } from 'recharts';

import { TotalTicketSoldProps } from './types';
import { common } from 'theme';

import * as Styled from '../AnalyticsSummary.styles';
import { MOCK_TICKETS_SOLD } from './constants';

export const TotalTicketsSold = ({ noGraph, ticketsSold, isEvent }: TotalTicketSoldProps) => {
	const { t } = useTranslation();

	return (
		<Styled.CardContainer>
			<Styled.Column>
				<Styled.Row>
					<Styled.InfoColumn>
						<Styled.CardName>{t('generalAnalytics-total-tickets-sold')}</Styled.CardName>
						<Styled.CardAmountTotal className="total">{ticketsSold}</Styled.CardAmountTotal>
					</Styled.InfoColumn>
				</Styled.Row>
				{!noGraph && (
					<Styled.ChartContainer className={isEvent ? 'isEvent' : ''}>
						<ResponsiveContainer>
							<AreaChart
								width={500}
								height={400}
								data={MOCK_TICKETS_SOLD}
								margin={{
									top: 10,
									right: 30,
									left: 0,
									bottom: 0,
								}}
							>
								<defs>
									<linearGradient id="ticketsColorFill" x1="0" y1="0" x2="0" y2="1">
										<stop offset="5%" stopColor={common.colors.crimson} stopOpacity={0.8} />
										<stop offset="95%" stopColor={common.colors.crimson} stopOpacity={0} />
									</linearGradient>
								</defs>
								<CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
								<Area
									type="monotone"
									dataKey="value"
									stroke={common.colors.crimson}
									fill="url(#ticketsColorFill)"
									strokeWidth={5}
								/>
							</AreaChart>
						</ResponsiveContainer>
					</Styled.ChartContainer>
				)}
			</Styled.Column>
		</Styled.CardContainer>
	);
};
