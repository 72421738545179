import styled from 'styled-components';
import { Text } from 'features/common/components/Text';
import { EventContainerStylesProps } from '../types';

export const EventContainer = styled.div<EventContainerStylesProps>`
	display: flex;
	align-items: center;
	width: 442px;
	height: 79px;
	left: 181px;
	top: 686px;
	background: ${({ secondary, theme }) =>
		secondary ? theme.colors.lightBlue : theme.colors.green};
	border-radius: 6px;
	padding: 0 9px 0 9px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 311px;
	}
`;

export const EventDate = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 69px;
	height: 61px;
	color: ${({ theme }) => theme.colors.black};
	background: ${({ theme }) => theme.colors.white};
	box-shadow: ${({ theme }) => theme.shadows.base};
	border-radius: 21px;
`;

export const WeekDay = styled(Text)`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: ${({ theme }) => theme.colors.black};
`;

export const Date = styled(Text)`
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	color: ${({ theme }) => theme.colors.black};
`;

export const InfoEvent = styled.div`
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

export const Title = styled(Text)`
	left: 284.13px;
	top: 702px;
	font-size: 16px;
	line-height: 24px;
`;

export const Time = styled(Text)`
	left: 284.13px;
	top: 729px;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
`;
