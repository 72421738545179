import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Form } from 'features/common/components/Form';
import { queryClient } from 'index';
import { EventProps } from 'features/Home/components/Events/types';
import { IdParamsType, RequiredFieldsProps } from 'utils/types';
import { ZERO_TIME } from 'utils/constants';
import { ticketingModeOptions } from 'features/CreateEvent/components/EventSummary/constants';

const LeftForm: FC<RequiredFieldsProps> = ({ isFromRequiredFields }: RequiredFieldsProps) => {
	const { t } = useTranslation();
	const { watch } = useFormContext();
	const { id }: IdParamsType = useParams();
	const event: EventProps = queryClient.getQueryData([`event-${id}`]) as EventProps;

	const address = watch('location');
	const sellingTime = watch('selling_time');
	const zeroTime = event?.selling_time === ZERO_TIME;

	return (
		<>
			<Form.Input id="name" name="name" label={t('editEvent-eventName')} />
			<Form.Input id="venue" name="venue" label={t('editEvent-venue')} />
			<Form.AddressAutocomplete id="location" name="location" label={t('editEvent-eventLocation')} value={address} />
			<Form.Input id="postal_code" name="postal_code" label={t('editEvent-zip-code')} />
			<Form.TimeZone />
			<Form.InputText id="description" name="description" label={t('editEvent-event-description')} />
			<Form.Select
				id="ticketing_mode"
				name="ticketing_mode"
				label={t('editEvent-event-ticketingMode')}
				options={ticketingModeOptions}
				withDefaultValue={false}
			/>
			<Form.InputDateAndTime
				id="selling_time"
				name="selling_time"
				label={t('editEvent-ticketsSaleAndTime')}
				value={zeroTime ? null : new Date(sellingTime)}
				isFromRequiredFields={isFromRequiredFields && zeroTime}
			/>
			<Form.InputText
				id="custom_email_message"
				name="custom_email_message"
				label={t('editEvent-custom-email-message')}
				subtitle={t('editEvent-custom-email-message-subtitle')}
			/>
			<Form.Input
				id="support"
				name="support"
				label={t('createEvent-advancedDetails-support')}
				placeholder={t('createEvent-advancedDetails-supportCopy')}
			/>
		</>
	);
};

export { LeftForm };
