import styled from 'styled-components';

import { HighlightsContainerProps } from './types';

import { hideScrollbar } from 'styles';

export const HighlightsContainer = styled.div<HighlightsContainerProps>`
	gap: 20px;
	display: flex;
	overflow-x: scroll;
	display: inline-flex;
	margin-right: 6%;
	margin: 0 3%;

	${hideScrollbar}

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		width: 100%;
		margin-right: 6%;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		padding: 10px 3%;
		margin-right: 1%;
		box-sizing: border-box;
	}
`;
