import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OUTLINED } from 'features/common/components/Button/constants';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Table } from 'features/common/components/Table';
import { COLUMN_HEADINGS, COLUMN_MAX_WIDTH, COLUMN_MIN_WIDTH, RESPONSES_INDEX } from './constants';
import { ResponsesProps } from 'features/CustomFieldsResponses/types';
import { TableBody } from './TableBody';
import { getCellIndex } from './utils';

import * as Styles from './ResponsesTable.styles';

export const ResponsesTable: FC<ResponsesProps> = ({ responsesQueryObject, isAttendeesList }) => {
	const { t, i18n } = useTranslation();
	const { fetchNextPage, hasNextPage, data } = responsesQueryObject;
	const [headings, setHeadings] = useState<string[]>([]);
	const [questions, setQuestions] = useState<string[]>([]);
	const [showDownloadQR, setShowDownloadQR] = useState(false);

	useEffect(() => {
		const tempHeadings = [...COLUMN_HEADINGS];
		if (!data || data.pages.length === 0) {
			setShowDownloadQR(false);
			setHeadings(tempHeadings);
			return;
		}

		const questions: string[] = [];
		data.pages.forEach((page) => {
			page.forEach((response) => {
				response.responses.forEach((response) => {
					if (!['ATTENDEE_NAME', 'ATTENDEE_EMAIL'].includes(response.custom_field.name))
						questions.push(response.custom_field.name);
				});
			});
		});

		const uniqueQuestions = Array.from(new Set(questions));
		setQuestions(uniqueQuestions);

		if (isAttendeesList) {
			tempHeadings.push(...uniqueQuestions);
		} else {
			tempHeadings.splice(RESPONSES_INDEX, 0, ...uniqueQuestions);
		}
		setHeadings(tempHeadings);

		if (data.pages.some((page) => page.some((response) => response.can_download_qr))) {
			setShowDownloadQR(true);
		}
	}, [data?.pages]);

	return (
		<>
			<Table.TableContainer>
				<Table>
					<thead>
						<Styles.HeaderRow gridColumns={`repeat(${headings.length}, 1fr)`} textAlign="center">
							{headings.map((heading, i) => (
								<Table.Cell
									key={heading}
									minWidth={COLUMN_MIN_WIDTH[getCellIndex(i, headings, RESPONSES_INDEX)]}
									maxWidth={COLUMN_MAX_WIDTH[getCellIndex(i, headings, RESPONSES_INDEX)]}
								>
									<Table.ColumnHeader>{i18n.exists(heading) ? t(heading) : heading}</Table.ColumnHeader>
								</Table.Cell>
							))}

							{isAttendeesList && showDownloadQR && (
								<Table.Cell minWidth={COLUMN_MIN_WIDTH[9]} maxWidth={COLUMN_MAX_WIDTH[9]} textAlign="center">
									<Table.ColumnHeader>{t('customFields-responses-ticketQR')}</Table.ColumnHeader>
								</Table.Cell>
							)}
						</Styles.HeaderRow>
					</thead>
					<TableBody
						responsesQueryObject={responsesQueryObject}
						columnsN={headings.length}
						questions={questions}
						isAttendeesList={isAttendeesList}
						showDownloadQR={showDownloadQR}
					/>
				</Table>
			</Table.TableContainer>
			<FlexContainer justifyContent="center" margin="32px 0">
				<Table.Button
					category={OUTLINED}
					text={t('button-load-more')}
					onClick={() => fetchNextPage()}
					disabled={!hasNextPage}
					type="button"
				/>
			</FlexContainer>
		</>
	);
};
