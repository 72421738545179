import { FC } from 'react';

import { Divider } from 'features/common/components/Divider';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { TicketsSectionTitleProps } from '../../../Dashboard/components/TicketsSection/types';

import { SButton, Row, SectionTitleContainer, Title, ButtonContainer } from './MultiButtonSectionTitle.styles';

const MultiButtonSectionTitle: FC<TicketsSectionTitleProps> = ({ title, buttons }: TicketsSectionTitleProps) => {
	const isLargeScreen = useResponsiveScreen();

	return (
		<SectionTitleContainer>
			<Row>
				<Title>{title}</Title>
				<ButtonContainer>
					{buttons.map(({ label, onClick, category = 'outlined', hidden = false, dataCy }) => (
						<SButton
							key={label}
							onClick={onClick}
							text={label}
							category={category}
							type="button"
							hidden={hidden}
							data-cy={dataCy}
						/>
					))}
				</ButtonContainer>
			</Row>
			{isLargeScreen && <Divider />}
		</SectionTitleContainer>
	);
};

export { MultiButtonSectionTitle };
