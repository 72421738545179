import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { camelizeKeys } from 'humps';

import { TotalPayout } from '../../AnalyticsSummary/CardsSummary/TotalPayout';
import { TotalAttendees } from '../../AnalyticsSummary/CardsSummary/TotalAttendees';
import { TotalTicketsSold } from '../../AnalyticsSummary/CardsSummary/TotalTicketsSold';
import { TotalTicketSoldProps } from '../../AnalyticsSummary/CardsSummary/types';
import { totalTicketSoldProps } from 'features/Dashboard/components/constants';
import { keepUpperCase } from 'utils/utils';
import { IdParamsType } from 'utils/types';
import { useHighlights } from 'hooks/useHighlights';

import * as Styled from './SummaryCardSection.styles';

const SummaryCardSection = () => {
	const { id } = useParams<IdParamsType>();
	const [analyticsCollection, setHighlights] = useState<TotalTicketSoldProps>(totalTicketSoldProps);
	const { eventHighlights } = useHighlights(id);

	useEffect(() => {
		if (eventHighlights) {
			const eventHighlightsCamel = camelizeKeys(eventHighlights, {
				// This function keeps ID as ID
				process: keepUpperCase,
			});
			setHighlights(eventHighlightsCamel);
		}
	}, [eventHighlights]);

	return (
		<Styled.SummaryCardsContainer>
			<TotalTicketsSold ticketsSold={analyticsCollection.ticketsSold} isEvent />
			<TotalPayout totalPayout={analyticsCollection.totalPayout} isEvent />
			<TotalAttendees totalAttendees={analyticsCollection.totalAttendees} isEvent />
		</Styled.SummaryCardsContainer>
	);
};

export default SummaryCardSection;
