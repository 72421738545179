import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import { getBase64 } from 'utils/utils';
import { useShowHoverOptions } from 'hooks/useShowHoverOptions';
import { elementId } from './utils';
import { EventImage as ImageType } from 'features/EventImgContent/types';
import { StyledDropzone } from 'features/common/components/ImageInputs/components/StyledDropzone';

import * as Styled from './EventImage.styles';
import { SubTitle, InfoText } from '../../MediaContent.styles';
import {
	EditButton,
	HoverOptions,
	ImageInputContainer,
} from 'features/common/components/ImageInputs/ImageInputs.styles';

const EventImage = () => {
	const { t } = useTranslation();
	const { setValue, watch, register } = useFormContext();
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: async (acceptedFiles) => {
			const valuesToAdd = await Promise.all(
				acceptedFiles.map(async (file) => ({
					url: URL.createObjectURL(file),
					base64: await getBase64(file),
					name: file.name,
				}))
			);
			setValue('mediaContent.eventImage', [...valuesToAdd]);
		},
		accept: {
			'image/jpeg': [],
			'image/png': [],
		},
		maxFiles: 1,
	});

	const [image]: ImageType[] = watch('mediaContent.eventImage');
	const bannerHeight = watch('mediaContent.bannerHeight');
	const imagePosition = watch('mediaContent.imagePosition');

	const { showHoverOptions } = useShowHoverOptions({
		elementId,
		hoverEnabled: image?.url.length > 0,
	});

	const labels = [
		{ label: t('website-media-content-banner-height'), inputName: 'mediaContent.bannerHeight', placeholder: '425' },
		{
			label: t('website-media-content-banner-height-mobile'),
			inputName: 'mediaContent.bannerHeightMobile',
			placeholder: '425',
		},
		{ label: t('website-media-content-top-position'), inputName: 'mediaContent.topPosition', placeholder: '400' },
		{
			label: t('website-media-content-top-position-mobile'),
			inputName: 'mediaContent.topPositionMobile',
			placeholder: '400',
		},
	];

	// const options = [
	// 	{ label: t('website-media-content-options-top'), value: 'top' },
	// 	{ label: t('website-media-content-options-center'), value: 'center' },
	// 	{ label: t('website-media-content-options-bottom'), value: 'bottom' },
	// ];

	return (
		<Styled.Container>
			<SubTitle>{t('generic-event-image')}</SubTitle>
			<InfoText>{t('website-media-content-eventImg-sizing')}</InfoText>
			<Styled.Box {...getRootProps()} height={bannerHeight} id={elementId}>
				<input {...getInputProps()} id="mediaContent.eventImage" data-cy="event-img-input" />
				{image?.url ? (
					<Styled.BoxImage
						src={image?.url}
						onError={(e) => (e.currentTarget.src = image?.url + '?alt=media')}
						alt={image?.name}
						objectPosition={imagePosition}
						showHoverOptions={showHoverOptions}
						data-cy="event-img"
					/>
				) : (
					<ImageInputContainer data-cy="image-container">
						<StyledDropzone />
					</ImageInputContainer>
				)}

				{showHoverOptions && image?.url.length > 0 && (
					<HoverOptions>
						<EditButton text={t('button-edit')} category="primary" data-cy="edit-img" />
						<EditButton
							text={t('button-delete')}
							category="tertiary"
							onClick={(e) => {
								e.stopPropagation();
								setValue('mediaContent.eventImage', []);
							}}
							data-cy="delete-img"
						/>
					</HoverOptions>
				)}
			</Styled.Box>

			<Styled.BannerPropertiesContainer>
				{labels.map(({ label, inputName, placeholder }) => (
					<Styled.InputContainer key={inputName}>
						<Styled.Label>{label}</Styled.Label>
						<Styled.Input {...register(inputName)} placeholder={placeholder} />
					</Styled.InputContainer>
				))}
			</Styled.BannerPropertiesContainer>

			{/* <Styled.InputContainer>
				<Form.Select
					id="mediaContent.imagePosition"
					name="mediaContent.imagePosition"
					label="Image Position"
					options={options}
					withDefaultValue={false}
				/>
			</Styled.InputContainer> */}
		</Styled.Container>
	);
};

export { EventImage };
