import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import { getData, post } from 'services/api';
import { common } from 'theme';
import { useToastNotification } from 'hooks/useToastNotification';
import { linkGreyBackground } from 'assets/images';
import { Header } from 'features/common/components/Header';
import { MainLayout } from 'layouts/MainLayout';
import { CircleWithIcon } from 'features/common/components/CircleWithIcon';
import { Form } from 'features/common/components/Form';
import { Footer } from 'features/common/components/Footer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { ROUTES } from 'features/routes';
import { AffiliateInfo, EventType, IdParamsType } from 'utils/types';
import { PostAffiliateProps } from './types';
import { defaultValues, resolver } from './utils';

import { StdText } from 'features/common/components/Text/Text.styles';
import { ContentWrap, CustomIcon, FormColumn } from './styles';

export const AffiliateCreate = () => {
	const { t } = useTranslation();
	const formMethods = useForm({ defaultValues, resolver });
	const { id: eventId }: IdParamsType = useParams();
	const { goBack, push } = useHistory();
	const { showNotification, TYPES } = useToastNotification();

	const { data: event } = useQuery<EventType>({
		queryKey: ['event', eventId],
		queryFn: () => getData(`/public/ticketing/events/${eventId}`),
		enabled: !!eventId,
	});

	const { mutate: postAffiliate } = useMutation({
		mutationFn: ({ linkName }: PostAffiliateProps) =>
			post('/events/affiliates', { event_id: parseInt(eventId), full_name: linkName }),
		onSuccess: ({ event_id }: AffiliateInfo) => {
			showNotification({ message: t('dashboard-affiliateCreate-success'), type: TYPES.SUCCESS });
			push(generatePath(ROUTES.DASHBOARD_VIEW_AFFILIATES, { id: event_id }));
		},
		onError: () => showNotification({ message: t('dashboard-affiliateCreate-failure'), type: TYPES.ERROR }),
	});

	return (
		<MainLayout color={common.colors.grey4}>
			<Header.TitledHeader
				title={event?.name || ''}
				subtitle={t('dashboard-affiliateCreate-subtitle')}
				CustomIcon={() => <CustomIcon src={linkGreyBackground} />}
			/>
			<ContentWrap>
				<FormColumn onSubmit={(data) => postAffiliate(data)} formMethods={formMethods}>
					<CircleWithIcon height="90px" width="90px" iconName="link" />
					<StdText>{t('dashboard-affiliateCreate-info')} </StdText>
					<Form.Input
						id="linkName"
						name="linkName"
						label={t('dashboard-affiliateCreate-input')}
						placeholder={t('placeholder-name')}
					/>
					<Footer>
						<Footer.Button type="button" onClick={goBack} category={OUTLINED} text={t('button-cancel')} />
						<Footer.Button text={t('button-create')} type="submit" />
					</Footer>
				</FormColumn>
			</ContentWrap>
		</MainLayout>
	);
};
