import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { ATTENDEES, TOTAL_TICKETS_SOLD } from 'features/Home/components/constants';
import { HighlightsCollection } from 'features/common/components/HighlightsCollection';
import { ContentWrapper } from 'features/Home/components/Highlights/Highlights.styles';
import { toSigDigits } from 'utils/utils';
import { YOUR_PAYOUT, COMPED, highlightsCollection } from '../constants';
import { HighlightsProps } from './types';
import { useHighlights } from 'hooks/useHighlights';

const Highlights: FC<HighlightsProps> = ({ isDashboard }: HighlightsProps) => {
	const { id }: { id: string } = useParams();
	const { eventHighlights } = useHighlights(id);
	const [boxCollection, setBoxCollection] = useState<BoxProps[]>(highlightsCollection);

	useEffect(() => {
		if (eventHighlights) {
			const _boxCollections: BoxProps[] = [];
			for (let i = 0; i < boxCollection.length; i++) {
				const box = boxCollection[i];
				if (box.type === YOUR_PAYOUT) {
					box.value = toSigDigits(eventHighlights.total_payout);
				} else if (box.type === TOTAL_TICKETS_SOLD) {
					box.value = eventHighlights.tickets_sold;
				} else if (box.type === ATTENDEES) {
					box.value = eventHighlights.total_attendees;
				} else if (box.type === COMPED) {
					box.value = eventHighlights.tickets_comped || 0;
				}
				_boxCollections.push(box);
			}
			setBoxCollection(_boxCollections);
		}
	}, [eventHighlights]);

	return (
		<ContentWrapper>
			<HighlightsCollection boxCollection={boxCollection} isDashboard={isDashboard} />
		</ContentWrapper>
	);
};

export { Highlights };
