import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { MainLayout } from 'layouts/MainLayout';
import { Form } from 'features/common/components/Form';
import { ROUTES } from 'features/routes';
import { defaultValues, validationSchema } from './utils';
import { uploadImage } from 'services/UploadImage/uploadImage';
import { createEvent } from './services';
import { ImageBackType, NewImageType } from './types';
import { useToastNotification } from 'hooks/useToastNotification';
import { sendGAEvent } from 'utils/utils';
import { EventSummary } from './components/EventSummary';
// import { getData } from 'services/api';

export const CreateEvent = () => {
	const { push } = useHistory();
	const { showNotification, TYPES } = useToastNotification();
	const { t } = useTranslation();
	const { CREATE_TICKET_PATH } = ROUTES;

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema(t)),
	});

	const { mutate: mutateCreateEvent } = useMutation(createEvent, {
		onSuccess: ({ data: { ID } }) => {
			sendGAEvent({ category: 'Event Creation', action: 'Create event success' });
			push(CREATE_TICKET_PATH.replace(':eventId', ID));
		},
		onError: () => {
			//TODO: do something
		},
	});

	const { mutateAsync: mutateUploadImage } = useMutation(uploadImage, {
		onSuccess: ({ data }) => {
			const newValues: NewImageType[] = [
				...formMethods.getValues('new_images'),
				{
					image: data.image_url,
					position: data.index + 1,
					main_image: !data.index,
				},
			];
			formMethods.setValue('new_images', newValues);
		},
		onError: () => {
			showNotification({
				message: t('error-uploading-image'),
				type: TYPES.ERROR,
			});
		},
	});
	// const { mutate: verifyZip } = useMutation({
	// 	mutationFn: () => getData(`/public/ticketing/avalara-confirmation/${formMethods.getValues('postal_code')}`),
	// 	onSuccess: () => submitForm(formMethods.getValues()),
	// 	onError: () => {
	// 		formMethods.setError('postal_code', {
	// 			type: 'manual',
	// 			message: t('createEvent-zip-code-error'),
	// 		});
	// 		formMethods.setFocus('postal_code');
	// 	},
	// });

	const submitForm = (currentValues: { images: ImageBackType[]; name: string }) => {
		Promise.all(
			currentValues.images.map((image, index) =>
				mutateUploadImage({
					image,
					prefix: currentValues.name,
					index,
					type: 'event',
				})
			)
		).then(() => {
			const currentValuesAfter = formMethods.getValues();
			mutateCreateEvent({ values: currentValuesAfter });
		});
	};

	return (
		<Form formMethods={formMethods} onSubmit={submitForm}>
			<MainLayout>
				<EventSummary />
			</MainLayout>
		</Form>
	);
};
