import styled from 'styled-components';
import { Text as UnstyledText } from 'features/common/components/Text';
import attendees from 'assets/images/attendeesBackground.svg';
import addOns from 'assets/images/addOnsbackground.svg';
import tickets from 'assets/images/ticketsSoldBackground.svg';
import payout from 'assets/images/payoutBackground.svg';
import events from 'assets/images/eventsBackground.svg';
import comped from 'assets/images/compedBackground.svg';
import { ContainerStylesProps } from 'features/Home/components/Highlights/types';
import { TOTAL_TICKETS_SOLD, EVENTS, ATTENDEES } from 'features/Home/components/constants';
import { YOUR_PAYOUT, COMPED, ADD_ONS } from 'features/Dashboard/components/constants';

export const EventsAmountContainer = styled.div`
	position: relative;
	top: 32px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const InProgressAmountContainer = styled.div`
	position: relative;
	top: 75px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const InProgressTextContainer = styled.div`
	position: relative;
	top: 75px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const EventsTextContainer = styled.div`
	position: relative;
	top: 32px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const AmountContainer = styled.div`
	position: relative;
	top: 127px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Amount = styled(UnstyledText)`
	font-weight: 600;
	font-size: 38px;
	line-height: 56px;
`;

export const TextContainer = styled.div`
	position: relative;
	top: 130px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Text = styled(UnstyledText)`
	font-weight: 400;
	font-size: 19px;
	line-height: 28px;
`;

export const Container = styled.div<ContainerStylesProps>`
	width: 294px;
	height: 274px;
	min-width: 294px;
	min-height: 274px;
	left: 940px;
	top: 295px;
	border-radius: 9px;
	background-image: ${({ type }) => type === TOTAL_TICKETS_SOLD && `url(${tickets})`};
	background-image: ${({ type }) => type === ATTENDEES && `url(${attendees})`};
	background-image: ${({ type }) => type === EVENTS && `url(${events})`};
	background-image: ${({ type }) => type === YOUR_PAYOUT && `url(${payout})`};
	background-image: ${({ type }) => type === COMPED && `url(${comped})`};
	background-image: ${({ type }) => type === ADD_ONS && `url(${addOns})`};
	border-radius: 9px;
	background-size: cover;
	background-repeat: no-repeat;
`;
