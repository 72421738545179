import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Form } from 'features/common/components/Form';

import { CustomQuestionContainer, Label, SelectsContainer, Wrapper } from './QuestionForm.styles';
import { useEffect } from 'react';

export const QuestionForm = () => {
	const { t } = useTranslation();

	const { watch, setValue } = useFormContext();

	const nameType = watch('custom_field.name_type');
	const onlyBuyer = watch('custom_field.only_buyer');

	useEffect(() => {
		if (onlyBuyer === 'buyerOnly') {
			setValue('custom_field.name_type', 'CUSTOM_QUESTION');
		}
	}, [onlyBuyer]);

	const questionOptions = [
		{ value: 'buyerOnly', optionLabel: t('customFields-buyerOnly') },
		{ value: 'eachAtendee', optionLabel: t('customFields-eachAttendee') },
	];

	const requiredQuestionOptions = [
		{ value: 'required', optionLabel: t('customFields-required') },
		{ value: 'notRequired', optionLabel: t('customFields-notRequired') },
	];

	const nameTypeOptions = [
		{ value: 'CUSTOM_QUESTION', optionLabel: t('customFields-nameType-custom') },
		{ value: 'ATTENDEE_NAME', optionLabel: t('customFields-nameType-name') },
		{ value: 'ATTENDEE_EMAIL', optionLabel: t('customFields-nameType-email') },
	];

	return (
		<Wrapper>
			<SelectsContainer>
				<Form.RadioButtonList
					id="custom_field.only_buyer"
					name="custom_field.only_buyer"
					options={questionOptions}
					label={t('customFields-answerer')}
				/>
			</SelectsContainer>

			{onlyBuyer !== 'buyerOnly' && (
				<SelectsContainer>
					<Form.RadioButtonList
						id="custom_field.name_type"
						name="custom_field.name_type"
						options={nameTypeOptions}
						label={t('customFields-nameType-label')}
						className="hidden"
					/>
				</SelectsContainer>
			)}

			{nameType === 'CUSTOM_QUESTION' && (
				<CustomQuestionContainer>
					<Label>{t('customFields-question')}</Label>
					<Form.Input id="custom_field.name" name="custom_field.name" placeholder="(E.g “Occupation”)" />

					<SelectsContainer>
						<Form.RadioButtonList
							id="custom_field.is_required"
							name="custom_field.is_required"
							options={requiredQuestionOptions}
							label={t('customFields-requiredQuestion')}
						/>
					</SelectsContainer>
				</CustomQuestionContainer>
			)}

			{['ATTENDEE_NAME', 'ATTENDEE_EMAIL'].includes(nameType) && (
				<Label>{t('customFields-name-email', { type: nameType === 'ATTENDEE_NAME' ? 'name' : 'email' })}</Label>
			)}
		</Wrapper>
	);
};
