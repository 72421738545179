import { useTranslation } from 'react-i18next';

import { Text } from 'features/common/components/Text';
import { AdvancedDetails } from './components/AdvancedDetails';
import { ModalCancel } from '../ModalCancel';
import { KeyDetails } from './components/KeyDetails';
import { useResponsiveScreen } from 'hooks/useWindowSize';

import { ButtonContainer, FieldSet, SubmitButton } from './EventSummary.styles';
import { BackIcon, ScreenTitle } from '../../CreateEvent.styles';

export const EventSummary = () => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();

	return (
		<>
			<ScreenTitle>
				<BackIcon iconName="arrowLeft" onClick={() => window.history.back()} />
				<Text data-cy="title">{t('create-event')}</Text>
				<ModalCancel />
			</ScreenTitle>
			<FieldSet>
				<KeyDetails />
				<AdvancedDetails />
				<ButtonContainer isMobile={isLargeScreen}>
					<SubmitButton text="Continue" type="submit" />
				</ButtonContainer>
			</FieldSet>
		</>
	);
};
