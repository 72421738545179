export const NO = 'NO';
export const YES = 'YES';

export const feeOptions = [
	{ value: 'false', optionLabel: 'No' },
	{ value: 'true', optionLabel: 'Yes' },
];

export const REGEX_URL =
	/((ftp|http|https):\/\/)(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const ZERO_TIME = '0001-01-01T00:00:00Z';

export const INVALID_TOKEN =
	'see https://firebase.google.com/docs/auth/admin/verify-id-tokens for details on how to retrieve a valid ID token';

export const UNAVAILABLE_TOKEN = 'Token not available';

export const DASHBOARD_AFFILIATES = 'dashboard-affiliates';
export const EVENTS_SEARCH_AND_FILTER = 'events-with-search-&-filter-';
