import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { MainWrapperStylesProps } from './types';

import { hideScrollbar } from 'styles';

export const Title = styled(Text)`
	line-height: 27px;
	margin: 20px 3%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 18px;
		margin-left: 3%;
	}
`;

export const MainWrapper = styled.div<MainWrapperStylesProps>`
	width: 100%;
	margin-left: 20px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		margin-left: 0px;
		overflow-x: scroll;
	}
`;

export const ContentWrapper = styled.div`
	margin-top: 26px;
	overflow: scroll;
	white-space: nowrap;

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;

export const HighlightsBox = styled.div`
	overflow-x: scroll;

	${hideScrollbar}
`;
