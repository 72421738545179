import styled from 'styled-components';

import { Icon } from 'features/common/components/Icon';

export const BackIcon = styled(Icon)`
	top: 30px;
	left: 30px;
	z-index: 2;
	position: absolute;
	cursor: pointer;
	padding: 10px;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: 70px;
	}
`;

export const Button = styled.button`
	all: unset;
	cursor: pointer;
`;

export const NextButton = styled.button`
	all: unset;
	width: 240px;
	height: 42px;
	cursor: pointer;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	background: ${({ theme }) => theme.colors.greenGradient3};

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

export const ButtonContainer = styled.div`
	gap: 16px;
	display: flex;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;

export const ScreenTitle = styled.div`
	position: fixed;
	height: 100px;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 3;
	background: ${({ theme }) => theme.colors.blackGrey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		top: 125px;
		background: transparent;
		position: unset;
	}
`;
