import { useFormContext } from 'react-hook-form';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { Toggle } from 'features/common/components/Toggle';
import { InputToggleProps } from './types';
import { InputLabel } from './InputLabel';

export const InputToggle = ({ name, ...rest }: InputToggleProps) => {
	const { watch, setValue } = useFormContext();
	const toggleValue = watch(name);

	return (
		<FlexContainer justifyContent="space-between" alignItems="center" margin="8px 0 0 0">
			<InputLabel {...rest} />
			<Toggle isChecked={toggleValue} handleClick={() => setValue(name, !toggleValue)} name={name} />
		</FlexContainer>
	);
};
