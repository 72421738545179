import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { Icon } from 'features/common/components/Icon';
import { matchPaymentLogo } from './utils';
import { formatNum } from 'features/RefundTicketsSummary/utils';
import { RefundBreakdown } from './RefundBreakdown';
import { SummaryContainerProps } from './types';
import { CreditCard } from 'services/FanTickets/types';

import * as Styled from './SummaryContainer.styles';

export const SummaryContainer: FC<SummaryContainerProps> = ({
	refProp,
	refundTotal,
	ticketData,
	purchaseTotal,
	refundPercent,
	refundTickets,
	cardsInfo,
	isMultipleCards,
}) => {
	const { t } = useTranslation();

	return (
		<Styled.InfoContainer>
			<Styled.HeaderBar>
				<Styled.CustomText>{`${t('refundTicketsSummary-summaryContainer-total-purchase')} $${formatNum(
					purchaseTotal
				)}`}</Styled.CustomText>
			</Styled.HeaderBar>
			<FlexContainer alignItems="center" gap="8px">
				<Icon iconName="invoice" />
				<Styled.SummaryTitle element="h3">
					{t('refundTicketsSummary-summaryContainer-refund-summary')}
				</Styled.SummaryTitle>
			</FlexContainer>
			<RefundBreakdown
				ticketData={ticketData}
				refundTotal={refundTotal}
				purchaseTotal={purchaseTotal}
				refundPercent={refundPercent}
			/>
			<Styled.PaymentInfo isMultipleCards={isMultipleCards}>
				<Styled.CustomText color="black3">{t('refundTicketsSummary-summaryContainer-refund-to')}</Styled.CustomText>
				{cardsInfo.map(({ id, last4, network }: CreditCard) => (
					<Styled.CardNumberFlex key={id}>
						{network !== '' && <Icon iconName={matchPaymentLogo(network)} size="XL" />}
						<Styled.HiddenText>{'....'}</Styled.HiddenText>
						<Styled.SmallBoldText>{last4}</Styled.SmallBoldText>
					</Styled.CardNumberFlex>
				))}
			</Styled.PaymentInfo>
			<Styled.RefundButton refProp={refProp} type="button" text={t('button-refund')} onClick={() => refundTickets()} />
		</Styled.InfoContainer>
	);
};
