import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal } from 'features/common/components/Modal';
import { RequiredModalProps } from './types';
import { ROUTES } from 'features/routes';

import * as Styled from './RequiredFields.styles';

export const RequiredFieldsModal: FC<RequiredModalProps> = ({ eventId, closeModal, eventGoLive }) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const [isTicketDetailsButton, setIsTicketDetailsButton] = useState(false);
	const [isFinanceSettingsButton, setIsFinanceSettingsButton] = useState(false);

	useEffect(() => {
		const partnerInfo = eventGoLive?.partner_info;
		const ticketTypeDetails = eventGoLive?.ticket_type_details;

		if (partnerInfo && (!partnerInfo.tax_info || !partnerInfo.payouts_info)) {
			setIsFinanceSettingsButton(true);
		}
		if (ticketTypeDetails) {
			const ticketValues = Object.values(ticketTypeDetails);
			if (ticketValues.length === 0) {
				setIsTicketDetailsButton(true);
			} else if (!eventGoLive.all_ticket_details) {
				setIsTicketDetailsButton(true);
			}
		}
	}, [eventGoLive]);

	const onClickEvent = () =>
		push(ROUTES.EDIT_EVENT_PATH.replace(':id', eventId.toString()), {
			isFromRequiredFields: true,
		});

	const onClickTicket = () => {
		const ticketDetails = eventGoLive?.ticket_type_details;

		if (!ticketDetails || Object.keys(ticketDetails).length === 0) {
			push(ROUTES.TICKET_NEW_PATH.replace(':id', eventId.toString()));
		} else {
			const incompleteTicketId = Object.keys(ticketDetails).find((key) => !ticketDetails[key]);

			if (incompleteTicketId) {
				push(ROUTES.TICKET_DETAILS_PATH.replace(':id', eventId.toString()).replace(':ticketId', incompleteTicketId), {
					isFromRequiredFields: true,
				});
			} else {
				setIsTicketDetailsButton(false);
			}
		}
	};

	const onClickFinanceSettings = () => {
		if (!eventGoLive?.partnerInfo?.tax_info) {
			push(ROUTES.SETTINGS_PATH, { showTab: 'tax_info', isFromRequiredFields: true });
		} else if (!eventGoLive?.partnerInfo.payouts_info) {
			push(ROUTES.SETTINGS_PATH, { showTab: 'payouts', isFromRequiredFields: true });
		}
	};

	return (
		<Styled.CustomModal onClose={closeModal}>
			<Modal.Header>{t('requiredFields')}</Modal.Header>
			<Styled.Wrapper>
				<Styled.IconAlert iconName="alert" />
				<Styled.ModalMessage className="title" element="p">
					{t('requiredFields-missing')}
				</Styled.ModalMessage>
				<Styled.ModalMessage element="p">{t('requiredFields-message')}</Styled.ModalMessage>
				{!eventGoLive?.event_details && (
					<Styled.StyledButton
						text={t('requiredFields-event-details')}
						type="button"
						category="quaternary"
						onClick={onClickEvent}
					/>
				)}
				{isTicketDetailsButton && (
					<Styled.StyledButton
						text={t('requiredFields-ticket-details')}
						type="button"
						category="quaternary"
						onClick={onClickTicket}
					/>
				)}
				{isFinanceSettingsButton && (
					<Styled.StyledButton
						text={t('requiredFields-finance-settings')}
						type="button"
						category="quaternary"
						onClick={onClickFinanceSettings}
					/>
				)}
			</Styled.Wrapper>
			<Styled.ActionsWrapper>
				<Styled.ModalButton
					text={t('dismiss')}
					type="button"
					onClick={() => {
						closeModal();
					}}
				/>
			</Styled.ActionsWrapper>
		</Styled.CustomModal>
	);
};
