import { FC, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	Box as CompedTickets,
	Box as Attendees,
	Box as YourPayout,
	Box as AddOns,
	Box as TotalTickets,
	Box as Events,
} from 'features/common/components/HighlightsCollection/Box';
import { TOTAL_TICKETS_SOLD, EVENTS, ATTENDEES } from 'features/Home/components/constants';
import { ADD_ONS, COMPED, YOUR_PAYOUT } from 'features/Dashboard/components/constants';
import { PositionlessBoxProps } from '../types';
import { IdParamsType } from 'utils/types';

import { Container } from './BoxClassifier.styles';

const boxContent = ({ type, value, text, viewRoute }: PositionlessBoxProps) => {
	const history = useHistory();
	const { id } = useParams<IdParamsType>();

	const onClick = viewRoute ? () => history.push(viewRoute.replace(':id', id)) : undefined;

	switch (type) {
		case TOTAL_TICKETS_SOLD:
			return <TotalTickets value={value} text={text} />;
		case EVENTS:
			return <Events value={value} text={text} />;
		case ATTENDEES:
			return <Attendees value={value} text={text} onClick={onClick} />;
		case YOUR_PAYOUT:
			return <YourPayout value={value} text={text} />;
		case COMPED:
			return <CompedTickets value={value} text={text} onClick={onClick} />;
		case ADD_ONS:
			return <AddOns value={value} text={text} />;
	}
};

const BoxClassifier: FC<PositionlessBoxProps> = ({ type, value, text, viewRoute }: PositionlessBoxProps) => (
	<Container type={type}>{boxContent({ type, value, text, viewRoute })}</Container>
);

const memorizedBoxClassifier: FC<PositionlessBoxProps> = memo(BoxClassifier);

export { memorizedBoxClassifier as BoxClassifier };
