import api from 'services/api';
import { convertDateWithTimezoneToUTC } from 'utils/utils';
import { CreateEventProps } from './types';
import { common } from '../../theme/common';

export const createEvent = ({ values }: CreateEventProps) => {
	const newDates = values.dates.map(({ start_date, end_date }) => ({
		start_date: convertDateWithTimezoneToUTC(start_date, values.timezone),
		end_date: convertDateWithTimezoneToUTC(end_date, values.timezone),
	}));

	const onSale = convertDateWithTimezoneToUTC(new Date(values.sale_date), values.timezone);

	return api.post('/events', {
		name: values.name,
		city: values.city,
		state: values.state,
		street_address: values.location,
		postal_code: values.postal_code,
		description: values.description,
		images: values.new_images,
		event_days: newDates,
		selling_time: onSale.includes('Invalid') ? null : onSale,
		time_zone: values.timezone,
		gate_time: values.gate_time ? Number(values.gate_time) : null,
		venue: values.venue,
		support_contact: [{ contact: values.support }],
		ticketing_mode: values.ticketing_mode,
		event_webpage_settings: {
			theme_color: common.colors.green13,
			header_color: common.colors.grey20,
			sub_title_brand_color: common.colors.grey19,
			sub_title_font_style: 'normal',
			text_brand_color: common.colors.grey19,
			text_font_style: 'normal',
			header_font_style: 'normal',
			header_brand_color: common.colors.white,
			callouts_font_style: 'normal',
			callouts_brand_color: common.colors.white,
			title_brand_color: common.colors.white,
			title_font_style: 'normal',
			background_color: common.colors.grey3,
		},
	});
};
