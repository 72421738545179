import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Search } from 'features/common/components/Search';
import { ResponsesTable } from './components/ResponsesTable/ResponsesTable';
import { MainHeader } from 'features/common/components/MainHeader';
import { MainLayout } from 'layouts/MainLayout';
import { useEventCustomFields } from './hooks/useEventCustomFields';
import { IdParamsType } from 'utils/types';
import { Icon } from 'features/common/components/Icon';
import { ROUTES } from 'features/routes';
import { getData } from 'services/api';
import { debounce, downloadXLSFile, sendGAEvent } from 'utils/utils';
import { CustomFieldsResponsesProps } from './types';

import * as Styled from './CustomFieldsResponses.styles';

export const CustomFieldsResponses = ({ isAttendeesList = false }: CustomFieldsResponsesProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { id } = useParams<IdParamsType>();
	const { setSearchText, responsesQueryObject } = useEventCustomFields(isAttendeesList);

	const searchDebounce = debounce(setSearchText);

	const handleDownload = async () => {
		const response = await getData(`/events/${id}/custom-fields/responses/download`, {
			params: { all_attendees: isAttendeesList },
			responseType: 'blob',
			headers: { 'Content-Type': 'blob' },
		});

		downloadXLSFile(response, 'Responses');
		sendGAEvent({ category: 'Custom Fields', action: 'Custom Fields Responses download' });
	};

	return (
		<MainLayout>
			<MainHeader lessPadding gap="12px">
				<Icon cursor="pointer" iconName="arrowLeft" onClick={() => push(generatePath(ROUTES.DASHBOARD_PATH, { id }))} />
				<Styled.Title element="h1">{t('customFields-responses-title')}</Styled.Title>
			</MainHeader>

			<Styled.Container>
				<Styled.CustomFlexContainer>
					<Search
						width="300px"
						onChange={(e) => searchDebounce(e.target.value)}
						placeholder={t('search-email-or-name')}
					/>
					<Styled.Buttons>
						<Styled.CsvButton onClick={handleDownload}>{t('customFields-responses-download')}</Styled.CsvButton>
					</Styled.Buttons>
				</Styled.CustomFlexContainer>

				<ResponsesTable responsesQueryObject={responsesQueryObject} isAttendeesList={isAttendeesList}></ResponsesTable>
			</Styled.Container>
		</MainLayout>
	);
};
