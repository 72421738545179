import { FC, memo } from 'react';
import { BoxProps } from 'features/Dashboard/components/Highlights/types';
import { useTranslation } from 'react-i18next';

import { AmountContainer, Amount, Text, TextContainer, Button, InfoContainer } from './Box.styles';

const Box = ({ value, text, onClick }: BoxProps) => {
	const { t } = useTranslation();
	return (
		<InfoContainer>
			<AmountContainer>
				<Amount>{value}</Amount>
			</AmountContainer>
			<TextContainer>
				<Text>{t(text)}</Text>
			</TextContainer>
			{onClick && <Button text="View" onClick={onClick} type="button" category="secondary" />}
		</InfoContainer>
	);
};

const memorizedBox: FC<BoxProps> = memo(Box);

export { memorizedBox as Box };
