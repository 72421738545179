import { useTranslation } from 'react-i18next';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer } from 'recharts';

import { TotalPayoutProps } from './types';
import { common } from 'theme';
import { toSigDigits } from 'utils/utils';
import { MOCK_GROSS_REV } from './constants';

import * as Styled from '../AnalyticsSummary.styles';

export const TotalPayout = ({ noGraph, totalPayout = 0, isEvent }: TotalPayoutProps) => {
	const { t } = useTranslation();
	return (
		<Styled.CardContainer>
			<Styled.Column>
				<Styled.Row>
					<Styled.InfoColumn>
						<Styled.CardName>{t('generalAnalytics-total-payout')}</Styled.CardName>
						<Styled.CardAmountRevenue className="revenue">{toSigDigits(totalPayout)}</Styled.CardAmountRevenue>
					</Styled.InfoColumn>
				</Styled.Row>
				{!noGraph && (
					<Styled.ChartContainer className={isEvent ? 'isEvent' : ''}>
						<ResponsiveContainer>
							<AreaChart
								width={500}
								height={400}
								data={MOCK_GROSS_REV}
								margin={{
									top: 10,
									right: 30,
									left: 0,
									bottom: 0,
								}}
							>
								<defs>
									<linearGradient id="colorFill2" x1="0" y1="0" x2="0" y2="1">
										<stop offset="5%" stopColor={common.colors.green8} stopOpacity={0.8} />
										<stop offset="95%" stopColor={common.colors.green8} stopOpacity={0} />
									</linearGradient>
								</defs>
								<CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
								<Area
									type="monotone"
									dataKey="value"
									stroke={common.colors.green9}
									fill="url(#colorFill2)"
									strokeWidth={5}
								/>
							</AreaChart>
						</ResponsiveContainer>
					</Styled.ChartContainer>
				)}
			</Styled.Column>
		</Styled.CardContainer>
	);
};
