import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TotalTicketsSold } from './CardsSummary/TotalTicketsSold';
import { TotalAttendees } from './CardsSummary/TotalAttendees';
import { TotalTicketSoldProps } from './CardsSummary/types';
import { totalTicketSoldProps } from 'features/Dashboard/components/constants';
import { useHighlights } from 'hooks/useHighlights';

import { Title, Wrapper } from 'features/Analytics/Analytics.styles';
import * as Styled from './AnalyticsSummary.styles';

export const AnalyticsSummary = () => {
	const { t } = useTranslation();
	const [highlightsCollection, setHighlights] = useState<TotalTicketSoldProps>(totalTicketSoldProps);
	const { highlights } = useHighlights();

	useEffect(() => {
		if (highlights) {
			const _highlightsCollection = {
				ticketsSold: highlights?.tickets_sold,
				totalPayout: highlights?.total_payout,
				totalAttendees: highlights?.total_attendees,
			};

			setHighlights(_highlightsCollection);
		}
	}, [highlights]);

	return (
		<Wrapper>
			<Title element="h2">{t('generalAnalytics-overall-summary')}</Title>
			<Styled.Container>
				{/* <TotalRegistrations ticketsSold={0} /> */}
				<TotalTicketsSold ticketsSold={highlightsCollection.ticketsSold} />
				{/* <TotalPayout totalPayout={highlightsCollection.totalPayout} /> */}
				<TotalAttendees totalAttendees={highlightsCollection.totalAttendees} />
			</Styled.Container>
		</Wrapper>
	);
};
