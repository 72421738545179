import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RefundBreakdownProps } from './types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { formatNum } from 'features/RefundTicketsSummary/utils';

import { CustomText } from '../SummaryContainer.styles';
import { List, ListItem, TotalContainer, Divider } from './RefundBreakdown.styles';

export const RefundBreakdown: FC<RefundBreakdownProps> = ({
	ticketData,
	refundTotal,
	purchaseTotal,
	refundPercent,
}) => {
	const { t } = useTranslation();

	return (
		<FlexContainer gap="24px" flexDirection="column">
			<FlexContainer gap="16px" flexDirection="column" padding="0 0 0 16px">
				<List>
					{ticketData.map(({ ticket_id, ticket_type, price_paid }) => (
						<ListItem key={ticket_id}>
							<CustomText>{ticket_type}</CustomText>
							<CustomText>{`$${price_paid}`}</CustomText>
						</ListItem>
					))}
				</List>
				<Divider />
				<FlexContainer justifyContent="space-between">
					<CustomText isBold>{t('refundTicketsSummary-refundBreakdown-subtotal')}</CustomText>
					<CustomText isBold>{`$${formatNum(purchaseTotal)}`}</CustomText>
				</FlexContainer>
				<FlexContainer justifyContent="space-between">
					<CustomText color="red" isBold>
						{t('refundTicketsSummary-refundBreakdown-refund-applied')}
					</CustomText>
					<CustomText color="red" isBold>{`-${refundPercent}`}</CustomText>
				</FlexContainer>
			</FlexContainer>
			<TotalContainer>
				<CustomText isBold> {t('refundTicketsSummary-refundBreakdown-total')}</CustomText>
				<CustomText isBold> {`$${formatNum(refundTotal)}`}</CustomText>
			</TotalContainer>
		</FlexContainer>
	);
};
