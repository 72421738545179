import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { Canvas } from 'features/common/animations/Canvas';
import backgroundBubbles from 'assets/images/backgroundBubbles.svg';
import { MainLayout } from 'layouts/MainLayout';
import { Form } from 'features/common/components/Form';
import { handleLink } from 'utils/handleLink';
import { ROUTES } from 'features/routes';
import { createBody, defaultValues, handleNavigation, validationSchema } from './utils';
import { StepCarousel } from './components/StepCarousel';
import { useTicketsApi } from './hooks';
import { useToastNotification } from 'hooks/useToastNotification';
import { EventIdParamsType } from 'utils/types';

import * as Styled from './CreateTicket.styles';

export const CreateTicket = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { hash } = useLocation();
	const { showNotification, TYPES } = useToastNotification();
	const { eventId }: EventIdParamsType = useParams();

	const ticketFormMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema(t)),
		mode: 'all',
	});

	const { event, mutateCreateTicket, mutateUploadImage } = useTicketsApi(ticketFormMethods);

	useEffect(() => handleLink('#1'), []);

	useEffect(() => {
		if (hash === '#4' || hash === '#6') ticketFormMethods.setValue('images', []);
	}, [hash]);

	const onSubmitTicketForm = (currentValues: any) => {
		const imageUploadPromises = [];

		if (currentValues.images[0]) {
			imageUploadPromises.push(
				mutateUploadImage({
					image: currentValues.images[0],
					prefix: `${eventId}-${currentValues.name}`,
					index: 0,
					type: 'template',
				})
			);
		} else if (currentValues.thumbnail) {
			imageUploadPromises.push(
				mutateUploadImage({
					image: { url: '', base64: currentValues.thumbnail, name: '' },
					prefix: `${eventId}-${currentValues.name}`,
					index: 0,
					type: 'thumbnail',
				})
			);
		}

		Promise.all(imageUploadPromises)
			.then(() => mutateCreateTicket(createBody(ticketFormMethods, Number(eventId), event?.time_zone || '')))
			.catch(() =>
				showNotification({
					message: t('error-uploading-image'),
					type: TYPES.ERROR,
				})
			);
	};

	const backScreenNumber = () => parseInt(hash.replace('#', ''), 10);
	const cancel = () => push(ROUTES.DASHBOARD_PATH.replace(':id', eventId));

	return (
		<Form formMethods={ticketFormMethods} onSubmit={onSubmitTicketForm}>
			<MainLayout>
				{backScreenNumber() > 1 && backScreenNumber() < 7 && (
					<a href={handleNavigation(backScreenNumber())}>
						<Styled.BackIcon iconName="arrowLeft" />
					</a>
				)}
				<Styled.ExitButton type="button" onClick={cancel}>
					<Styled.ExitIcon iconName="close" />
				</Styled.ExitButton>
				{(backScreenNumber() < 2 || backScreenNumber() > 3) && <Canvas />}
				<Styled.BackgroundImage src={backgroundBubbles} />
				<StepCarousel />
			</MainLayout>
		</Form>
	);
};
