import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Icon } from 'features/common/components/Icon';
import { Button } from 'features/common/components/Button';

export const FieldSet = styled.div`
	box-sizing: border-box;
	gap: 16px;
	width: 100%;
	display: flex;
	overflow: scroll;
	max-width: 700px;
	margin-top: 100px;
	margin-bottom: 134px;
	align-items: center;
	flex-direction: column;
	padding: 0 32px;

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0 0;
	}
`;

interface ButtonContainerProps {
	isMobile: boolean;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
	position: ${({ isMobile }) => isMobile && 'fixed'};
	height: 100px;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 4;
	background: ${({ theme }) => theme.colors.blackGrey};
	width: 100%;
`;

export const DropdownContainer = styled.button`
	all: unset;
	width: 100%;
	display: flex;
	padding: 16px;
	cursor: pointer;
	border-radius: 8px;
	align-items: center;
	box-sizing: border-box;
	justify-content: space-between;
	border: ${({ theme }) => `1px solid ${theme.colors.grey8}`};
`;

export const DropdownText = styled(Text)`
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	text-transform: none;
`;

export const Arrow = styled(Icon)`
	width: 16px;
	height: 16px;

	&.inactive {
		transition: 0.6s ease;
		transform: rotate(0deg);
	}

	&.active {
		transition: 0.6s ease;
		transform: rotate(180deg);
	}
`;

export const SubmitButton = styled(Button)`
	width: 240px;
	height: 42px;
	display: flex;
	margin: 10px 0;
	cursor: pointer;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};
`;
