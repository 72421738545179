export const MOCK_TICKETS_SOLD = [
	{
		name: 'Week 1',
		value: 1000,
	},
	{
		name: 'Week 2',
		value: 2000,
	},
	{
		name: 'Week 3',
		value: 5000,
	},
	{
		name: 'Week 4',
		value: 1080,
	},
];

export const MOCK_REGISTRATIONS = [
	{
		name: 'Week 1',
		value: 0,
	},
	{
		name: 'Week 2',
		value: 0,
	},
	{
		name: 'Week 3',
		value: 0,
	},
	{
		name: 'Week 4',
		value: 0,
	},
];

export const MOCK_TOTAL_ATTENDEES = [
	{
		name: 'Week 1',
		value: 0,
	},
	{
		name: 'Week 2',
		value: 2000,
	},
	{
		name: 'Week 3',
		value: 3000,
	},
	{
		name: 'Week 4',
		value: 8100,
	},
];

export const MOCK_GROSS_REV = [
	{
		name: 'Week 1',
		value: 0,
	},
	{
		name: 'Week 2',
		value: 500,
	},
	{
		name: 'Week 3',
		value: 1000,
	},
	{
		name: 'Week 4',
		value: 3080,
	},
];
