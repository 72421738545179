import styled from 'styled-components';

import { Text as UnstyledText } from 'features/common/components/Text';
import { Button as UnstyledButton } from 'features/common/components/Button';

export const AmountContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Amount = styled(UnstyledText)`
	font-weight: 600;
	font-size: 36px;
	line-height: 36px;
`;

export const TextContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Text = styled(UnstyledText)`
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
`;

export const Button = styled(UnstyledButton)`
	border-radius: 16px;
	font-weight: 600;
	padding: 4px 22px;
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.black};
	text-decoration: none;
	mix-blend-mode: screen;
`;

export const InfoContainer = styled.div`
	position: relative;
	top: 127px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`;
