import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { BoxImageProps, BoxProps } from './types';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Label = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	line-height: 32px;
`;

export const Box = styled.div<BoxProps>`
	all: unset;
	width: 100%;
	height: ${({ height }) => height || '210'}px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	position: relative;
`;

export const BoxImage = styled.img<BoxImageProps>`
	width: auto;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
	width: 100%;
	opacity: ${({ showHoverOptions }) => (showHoverOptions ? 0.3 : 1)};
	object-position: ${({ objectPosition }) => objectPosition || 'center center'};
`;

export const BannerPropertiesContainer = styled.div`
	margin-top: 20px;
	display: flex;
	gap: 16px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Input = styled.input`
	all: unset;
	width: 100%;
	height: 48px;
	padding: 10px;
	font-size: 20px;
	margin-top: 10px;
	padding-right: 50px;
	border-radius: 4px;
	text-align: left;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.grey2};
	font-family: ${({ theme }) => theme.fonts.primary};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: 90%;
	}
`;
