import { FC } from 'react';
import { Table } from 'features/common/components/Table';
import { OrderProps, OrdersData, Page } from 'features/Orders/types';
import { formatDate } from './utils';
import { COLUMN_MIN_WIDTH } from './constants';

import { getStatusColor } from './OrdersTable.styles';

export const TableBody: FC<OrderProps> = ({ ordersQueryObject }: OrderProps) => {
	const { data: orders } = ordersQueryObject;

	const statusStyle = (status: string) => ({
		backgroundColor: getStatusColor(status),
	});

	const renderCell = (minWidth: number, value: any) => <Table.Cell minWidth={minWidth}>{value}</Table.Cell>;

	return (
		<tbody>
			{orders?.pages.map((page: Page) =>
				page.data.map((order: OrdersData) => (
					<Table.DataRow gridColumns="repeat(8, 1fr)" key={order.id} textAlign="center">
						{renderCell(COLUMN_MIN_WIDTH[0], order.User?.full_name)}
						{renderCell(COLUMN_MIN_WIDTH[1], order.User?.email)}
						{renderCell(
							COLUMN_MIN_WIDTH[2],
							order.events_name?.length > 1 ? order.events_name.join(', ') : order.events_name[0]
						)}
						{renderCell(
							COLUMN_MIN_WIDTH[3],
							order.tickets_name?.length > 1 ? order.tickets_name.join(', ') : order.tickets_name[0]
						)}
						{renderCell(COLUMN_MIN_WIDTH[4], order.quantity)}
						{renderCell(COLUMN_MIN_WIDTH[5], order.price?.toFixed(2))}
						{renderCell(COLUMN_MIN_WIDTH[6], formatDate(order.date_time))}
						<Table.Cell className="statusCell" style={statusStyle(order.status)} minWidth={COLUMN_MIN_WIDTH[7]}>
							{order.status}
						</Table.Cell>
					</Table.DataRow>
				))
			)}
		</tbody>
	);
};
