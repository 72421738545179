import styled from 'styled-components';

export const SummaryCardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	overflow-x: auto;
	overflow-y: hidden;
	justify-content: space-around;
	width: 91%;
	padding: 15px 3% 15px 6%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: flex-start;
		width: 94%;
		padding: 15px 3%;
	}
`;
