import { v4 as uuid } from 'uuid';
import { TFunction } from 'react-i18next';
import { DateSchema, array, date, object, string } from 'yup';

import { DefaultValuesTypes } from './types';

const today = new Date();

export const defaultValues: DefaultValuesTypes = {
	name: '',
	location: '',
	city: '',
	state: '',
	postal_code: '',
	description: '',
	images: [],
	new_images: [],
	dates: [
		{
			start_date: today,
			end_date: today,
			id: uuid(),
		},
	],
	sale_date: today,
	timezone: '',
	gate_time: '',
	venue: '',
	support: '',
	ticketing_mode: 'static',
};

export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		name: string().required(t('editEvent-requiredName')),
		location: string().required(t('editEvent-requiredLocation')),
		postal_code: string().required(t('editEvent-requiredZipcode')),
		timezone: string().required(t('editEvent-requiredTimezone')),
		dates: array(
			object({
				start_date: date().required(),
				end_date: date()
					.required()
					.when('start_date', (start_date: Date, schema: DateSchema) => schema.min(start_date, t('error-endDate'))),
			})
		),
	});
