import { useQuery } from '@tanstack/react-query';
import { CustomField } from 'features/CustomFields/types';
import { useParams } from 'react-router-dom';

import { getData } from 'services/api';
import { IdParamsType } from 'utils/types';

export const useGetCustomFields = () => {
	const { id: eventId } = useParams<IdParamsType>();

	const { data } = useQuery({
		queryKey: ['custom-fields', eventId],
		queryFn: (): Promise<CustomField[]> => getData(`/public/ticketing/events/${eventId}/custom-fields`),
		enabled: !!eventId,
	});

	return { customFields: data };
};
