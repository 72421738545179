import { TFunction } from 'react-i18next';
import { DateSchema, array, date, number, object, string } from 'yup';

import { CustomFee, DefaultValuesTypes, Event, EventDateType } from './types';
import { convertUTCToDate } from 'utils/utils';
import { ZERO_TIME } from 'utils/constants';

export const defaultValues: DefaultValuesTypes = {
	name: '',
	venue: '',
	location: '',
	description: '',
	selling_time: null,
	dates: [],
	custom_fees: [],
	timezone: '',
	gate_time: '',
	postal_code: '',
	isMultiDay: false,
	ticketing_mode: 'dynamic',
	custom_email_message: '',
	support: '',
};

export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		name: string().required(t('editEvent-requiredName')),
		location: string().required(t('editEvent-requiredLocation')),
		postal_code: string().required(t('editEvent-requiredZipcode')),
		custom_fees: array(
			object({
				name: string().required('Required'),
				amount: number()
					.required()
					.when('type', {
						is: 'percentage',
						then: number().min(0, 'Must be a number between 0 and 100').max(100, 'Must be a number between 0 and 100'),
					}),
			})
		),
		dates: array(
			object({
				start_date: date().required(),
				end_date: date()
					.required()
					.when('start_date', (start_date: Date, schema: DateSchema) => schema.min(start_date, t('error-endDate'))),
			})
		),
	});

export const handleInitialValues = (event: Event) => {
	const dates = event.days.map(({ start_date, end_date, id }: EventDateType) => ({
		start_date: new Date(convertUTCToDate(start_date, event?.time_zone)),
		end_date: new Date(convertUTCToDate(end_date, event?.time_zone)),
		id,
	}));

	const selling_time =
		event.selling_time === ZERO_TIME ? event.selling_time : convertUTCToDate(event.selling_time, event.time_zone);

	const custom_fees = event.custom_fees?.map(({ ID, name, amount, type, taxable }: CustomFee) => ({
		ID,
		name,
		amount,
		type,
		taxable,
	}));

	const allValues: DefaultValuesTypes = {
		name: event.name,
		location: event.street_address,
		venue: event.venue,
		description: event.description,
		selling_time,
		dates: dates,
		timezone: event.time_zone,
		gate_time: event.gate_time ? event.gate_time : '',
		postal_code: event.postal_code,
		isMultiDay: event.days.length > 1,
		ticketing_mode: event.ticketing_mode,
		custom_fees: custom_fees || [],
		custom_email_message: event.custom_email_message,
		support: event.support_contact?.[0]?.contact || '',
	};
	return allValues;
};
