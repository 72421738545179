import { Dispatch, MouseEvent, SetStateAction } from 'react';
import ReactGA from 'react-ga4';
import { HumpsProcessor, camelizeKeys } from 'humps';
import moment from 'moment-timezone';
import { jwtDecode } from 'jwt-decode';

import { CustomToken, GAEventType, GAPageType } from './types';

export const today = new Date();
export const unlimitedDate = new Date('1/1/1970');

export const nuveiTax1 = 0.0294;
export const nuveiTax2 = 0;

export const sendGAPage = ({ page, title }: GAPageType) => ReactGA.send({ hitType: 'pageview', page, title });

export const sendGAEvent = ({ category, action }: GAEventType) => ReactGA.event({ category, action });

export const setGAUser = (userId: number) => ReactGA.set({ userId });

export const getBase64 = async (file: Blob) =>
	new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const baseURL = reader.result;
			resolve(baseURL);
		};
	});

export const getCurrentTime = (date: Date) => date.toString().split(' ')[4];

export const getCurrentDateNoTime = (date: Date) => {
	const currentDateArray = date.toString().split(' ');
	const actualDayNoTime = `${currentDateArray[0]} ${currentDateArray[1]} ${currentDateArray[2]} ${currentDateArray[3]}`;
	return actualDayNoTime;
};

export const convertDateWithTimezoneToUTC = (date: Date, timezone: string) => {
	const dateWithTimezone = moment.tz(moment(date).format('YYYY-MM-DD HH:mm'), timezone.split(' ')[0]);
	const dateInUTC = dateWithTimezone.utcOffset(0).format('YYYY-MM-DDTHH:mm:ss[Z]');

	return dateInUTC;
};

export const convertUTCToDate = (date: string, timezone: string) => {
	timezone = timezone === '' ? Intl.DateTimeFormat().resolvedOptions().timeZone : timezone;
	const newTimezone = timezone?.split(' ')[0];
	const dateUTC = moment.utc(date);
	const dateWithTimezone = dateUTC.tz(newTimezone);
	const finalDate = dateWithTimezone?.format('YYYY-MM-DD HH:mm:ss');
	return finalDate;
};

export const convertUTCToDateBarFormat = (date: string, timezone = '', dateFormatType = '') => {
	const dateFormat = dateFormatType || 'MM/DD/YYYY';
	const timeFormat = 'hh:mmA';
	if (timezone && !dateFormatType) {
		const newTimezone = timezone.split(' ')[0];
		const dateUTC = moment.utc(date);
		const dateWithTimezone = dateUTC.tz(newTimezone);
		const finalDate = dateWithTimezone.format(`${dateFormat}${timeFormat}`);
		return { date: finalDate.slice(0, 10), time: finalDate.slice(10) };
	} else {
		return {
			date: moment(date).utc().format(dateFormat),
			time: moment(date).utc().format(timeFormat),
		};
	}
};

export const debounce = (callback: Dispatch<SetStateAction<string>>) => {
	let timerId: number;

	return (...args: string[]) => {
		window.clearTimeout(timerId);
		timerId = window.setTimeout(callback, 500, ...args);
	};
};

// This function keeps ID as ID
export const keepUpperCase: HumpsProcessor = (key, convert, options) =>
	/^[A-Z]+$/.test(key) ? key : convert(key, options);

export const camelize = (object: unknown) =>
	camelizeKeys(object, {
		process: keepUpperCase,
	});

export const toUsFormat = (amount: number) => {
	const usDollar = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});
	return usDollar.format(amount);
};

export const toSigDigits = (amount = 0) => {
	if (amount < 1000) {
		return toUsFormat(amount);
	} else if (amount < 99999) {
		return toUsFormat(Math.round(amount)).slice(0, -3);
	} else {
		return `${toUsFormat(Math.round(amount) / 1000).slice(0, -3)}k`;
	}
};

export const formatTime = (timeString: string) => {
	const [hourString, minute] = timeString.split(':');
	const hour = +hourString % 24;
	return (hour % 12 || 12) + ':' + minute + (hour < 12 ? 'am' : 'pm');
};

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const customXTick = (date: Date) => {
	const momentYear = moment(date).year();
	if (isNaN(momentYear) || momentYear < 1980) return '';
	const dateFormat = momentYear == moment().year() ? 'MM/DD' : 'MM/DD/YYYY';
	return moment(date)?.format(dateFormat);
};

export const formatMoney = (price: number | string | null) => {
	if (price === null || price === '') return '';
	const numberPrice = typeof price === 'string' ? parseFloat(price) : price;
	const test = Number.isInteger(numberPrice) ? `${price}` : numberPrice.toFixed(2);
	return test;
};

export const openFileInNewTab = (e: MouseEvent<HTMLElement>, fileStr: string) => {
	e.stopPropagation();
	const idx = fileStr.indexOf('base64,') + 7;
	const base64Str = fileStr.slice(idx);
	const byteCharacters = atob(base64Str);
	const byteArrays = [];

	for (let i = 0; i < byteCharacters.length; i++) {
		byteArrays.push(byteCharacters.charCodeAt(i));
	}

	const byteArray = new Uint8Array(byteArrays);
	const blob = new Blob([byteArray], { type: 'application/pdf' });
	window.open(URL.createObjectURL(blob), '_blank');
};

export const isScannerRole = (token: string) => {
	const decodedToken = jwtDecode(token);
	const roles = Object.keys((decodedToken as CustomToken).claims?.memberRoles || {});
	return roles.length === 1 && roles.includes('scanner');
};

export const downloadXLSFile = async (data: string, filename: string) => {
	const outputFilename = `${filename}.xlsx`;

	const url = URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', outputFilename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const urlWebsite = (id: number) => `${process.env.REACT_APP_WEBSITE_URL}/${id}`;

export const urlPreviewPage = (id: number) => `${process.env.REACT_APP_WEBSITE_URL}/${id}?preview=${true}`;

export const isBlob = (link: string): boolean => link.includes('blob:');
