import { FC, memo } from 'react';

import { EventCardProps } from '../types';
import { SHORT_WEEKDAYS } from '../../constants';

import { EventContainer, Date, EventDate, Time, Title, WeekDay, InfoEvent } from './EventCard.styles';

const EventCard: FC<EventCardProps> = ({ handleClick, title, time, weekday, day, secondary }: EventCardProps) => (
	<EventContainer secondary={secondary} onClick={handleClick}>
		<EventDate>
			<Date>{day}</Date>
			<WeekDay>{SHORT_WEEKDAYS[weekday]}</WeekDay>
		</EventDate>
		<InfoEvent>
			<Title>{title}</Title>
			<Time>{time}</Time>
		</InfoEvent>
	</EventContainer>
);

const memorizedEventCard: FC<EventCardProps> = memo(EventCard);

export { memorizedEventCard as EventCard };
