import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { useGetCustomFields } from 'features/common/components/CustomFieldsComponent/useGetCustomFields';
import { CustomFieldsComponent } from 'features/common/components/CustomFieldsComponent';
import { ROUTES } from 'features/routes';
import { MultiButtonSectionTitle } from 'features/common/components/MultiButtonSectionTitle';
import { IdParamsType } from 'utils/types';

export const CustomFieldsSection = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { id } = useParams<IdParamsType>();

	const { customFields } = useGetCustomFields();

	const onClickReorder = () => push(generatePath(ROUTES.DASHBOARD_REORDER_CUSTOM_FIELDS, { id }));
	const onClickAddNew = () => push(generatePath(ROUTES.DASHBOARD_ADD_CUSTOM_FIELDS, { eventId: id }));
	const onClickViewResponses = () => push(generatePath(ROUTES.DASHBOARD_CUSTOM_FIELDS_RESPONSES, { id }));

	// TODO: Needed for testing purposes only. Remove after implementing Registration Events dashboard.
	const hideReorderButton = process.env.REACT_APP_ENV === 'production';
	const buttons = [
		{ label: t('dashboard-reorder'), onClick: onClickReorder, dataCy: 'reorder-button', hidden: hideReorderButton },
		{ label: t('dashboard-viewResponses'), onClick: onClickViewResponses, hidden: customFields?.length === 0 },
		{ label: t('dashboard-add'), onClick: onClickAddNew },
	];

	return (
		<>
			<MultiButtonSectionTitle title={t('dashboard-custom-fields')} buttons={buttons} />
			<CustomFieldsComponent />
		</>
	);
};
