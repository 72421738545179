import { CustomField } from './components/CustomField';
import { useGetCustomFields } from './useGetCustomFields';
import { CustomField as CustomFieldType } from 'features/CustomFields/types';

import { Wrapper } from './CustomFieldsComponent.styles';

export const CustomFieldsComponent = () => {
	const { customFields } = useGetCustomFields();

	return (
		<Wrapper>
			{customFields?.map((field: CustomFieldType) => (
				<CustomField key={field.ID} {...field} />
			))}
		</Wrapper>
	);
};
