import { useEffect, useState } from 'react';

import { getData } from 'services/api';
import { EventType } from './types';

export const useToggleEventLive = (id: number, platform: EventType, currentStatus: boolean) => {
	const [showReqFields, setShowReqFields] = useState(false);
	const [eventGoLive, setEventGoLive] = useState(null);
	const [toggleChecked, setToggleChecked] = useState(currentStatus);

	useEffect(() => {
		setToggleChecked(currentStatus);
	}, [currentStatus]);

	const handleToggle = () => {
		getData(`/events/${id}/${platform}/${toggleChecked ? 'take-offline' : 'go-live'}`).then((data) => {
			if (toggleChecked) {
				setToggleChecked(false);
			} else {
				setEventGoLive(data);
				if (
					Object.keys(data)
						.filter((key) => key !== 'ticket_type_details')
						.every(
							(key) => data[key] === true || (key === 'partner_info' && data[key]?.tax_info && data[key]?.payouts_info)
						)
				) {
					setToggleChecked(true);
				} else setShowReqFields(true);
			}
		});
	};

	return {
		toggleChecked,
		handleToggle,
		showReqFields,
		reqFieldsProps: {
			eventGoLive,
			eventId: id,
			closeModal: () => setShowReqFields(false),
		},
	};
};
