import { useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import { TICKETS_SOLD } from 'features/Dashboard/components/constants';
import { UseChartResponse } from './types';
import { getTicketsSold, processTimeline, initWindowSize, reducer } from './utils';
import { useHighlights } from 'hooks/useHighlights';

const useChart = (chart: string): UseChartResponse => {
	const { id }: { id: string } = useParams();
	const [displayWindow, setDisplay] = useReducer(reducer, {
		xAxis: 'days',
		windowStart: 0,
		windowSize: initWindowSize,
		windowSizeShadow: initWindowSize,
		dataPoints: 0,
	});

	const { eventHighlights } = useHighlights(id, true);

	const dataByTimeline = useMemo(
		() => processTimeline({ eventHighlights, timeline: displayWindow.xAxis }),
		[eventHighlights, displayWindow.xAxis]
	);

	const displayedWindow = dataByTimeline
		? dataByTimeline?.sales.slice(displayWindow.windowStart, displayWindow.windowStart + displayWindow.windowSize)
		: [];

	useEffect(() => {
		if (dataByTimeline) setDisplay({ type: 'init', payload: dataByTimeline.sales.length });
	}, [dataByTimeline?.sales.length]);

	if (chart === TICKETS_SOLD) {
		return { ...getTicketsSold(displayedWindow, eventHighlights?.ticket_types_inventory), setDisplay, displayWindow };
	} else {
		return { dataFrame: displayedWindow, setDisplay, displayWindow };
	}
};

export { useChart };
