import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ticketsRefundPost } from './services';
import { useResponsiveScreenLG } from 'hooks/useWindowSize';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { fanTicketDataGet } from 'services/FanTickets';
import { FanTicketData } from 'services/FanTickets/types';
import { Header } from 'features/common/components/Header';
import { ConditionalWrapper } from 'features/common/components/ConditionalWrapper';
import { RefundOptions } from './components/RefundOptions';
import { RefundOutcomes } from './components/RefundOutcomes';
import { SummaryContainer } from './components/SummaryContainer';
import { OptionsEnum, ResolverEnum, RefundTicketSummaryParams } from './types';
import { getRefundDetails, getCardInfo } from './utils';
import { MainLayout } from 'layouts/MainLayout';

import * as Styled from './RefundTicketsSummary.styles';

export const RefundTicketsSummary = () => {
	const isDesktop = useResponsiveScreenLG();
	const isLargeScreen = useResponsiveScreen();
	const { t } = useTranslation();
	const [refundType, setRefundType] = useState<OptionsEnum>(OptionsEnum.FULL);
	const [resolver, setResolver] = useState<ResolverEnum>(ResolverEnum.percentage);
	const [partialAmount, setPartialAmount] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const { ref, inView } = useInView({ threshold: 0.75 });
	const { ticketIds, fanId } = useParams<RefundTicketSummaryParams>();
	const { data } = useQuery({
		queryKey: ['refund-tickets-summary'],
		queryFn: (): Promise<FanTicketData> => fanTicketDataGet({ ticketIds, fanId, isSummaryPage: true }),
		refetchOnWindowFocus: false,
	});
	const ticketData = data?.refundable_tickets || [];
	const { refundPerTicket, purchaseTotal, refundTotal, refundPercent, minTicketPrice } = useMemo(
		() => getRefundDetails(resolver, partialAmount, refundType, data?.refundable_tickets),
		[data?.refundable_tickets, resolver, partialAmount, refundType]
	);

	const { mutate: refundTickets, data: refundData } = useMutation({
		mutationFn: () => ticketsRefundPost({ refundPerTicket, description }),
	});
	const { isMultipleCards, cardsInfo } = useMemo(() => getCardInfo(data), [data?.refundable_tickets.length]);

	return (
		<MainLayout containsStickyEl>
			<Header.TitledHeader title={t('refundableTickets')} subtitle={data ? data.user_info.email : ''} />
			<Styled.BodyContainer>
				<RefundOptions
					optionsState={[refundType, setRefundType]}
					resolverState={[resolver, setResolver]}
					partialAmountState={[partialAmount, setPartialAmount]}
					setDescription={setDescription}
					purchaseTotal={purchaseTotal}
					minTicketPrice={minTicketPrice}
					numberOfTickets={ticketData.length}
				/>
				<ConditionalWrapper
					condition={isDesktop}
					wrapper={(children) => <Styled.LightBackground>{children}</Styled.LightBackground>}
				>
					<SummaryContainer
						refProp={ref}
						ticketData={ticketData}
						refundTotal={refundTotal}
						purchaseTotal={purchaseTotal}
						refundPercent={refundPercent}
						refundTickets={refundTickets}
						cardsInfo={cardsInfo}
						isMultipleCards={isMultipleCards}
					/>
				</ConditionalWrapper>
			</Styled.BodyContainer>
			{!inView && !isLargeScreen && (
				<Styled.BackgroundBlur>
					<Styled.RefundButton text={t('button-refund')} type="button" onClick={() => refundTickets()} />
				</Styled.BackgroundBlur>
			)}
			<RefundOutcomes
				refundData={refundData}
				refundTickets={refundTickets}
				refundPerTicket={refundPerTicket}
				ticketData={ticketData}
			/>
		</MainLayout>
	);
};
