import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HighlightsCollection } from 'features/common/components/HighlightsCollection';
import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { EmptyHighlights } from './EmptyHighlights';
import { HighlightsProps } from './types';
import { highlightsCollection } from './utils';
import { useHighlights } from 'hooks/useHighlights';

import * as Styled from './Highlights.styles';

const Highlights: FC<HighlightsProps> = ({ hasLogo }: HighlightsProps) => {
	const { t } = useTranslation();
	const [boxCollection, setBoxCollection] = useState<BoxProps[]>(highlightsCollection);
	const { highlights, isLoading } = useHighlights();
	const hasHighlights = highlights !== undefined && !!Object.values(highlights).reduce((t, n) => t + n);

	useEffect(() => {
		if (highlights) {
			const _boxCollections: BoxProps[] = [];
			Object.entries(highlights).forEach(([key, value]) => {
				const _box = boxCollection.find((box) => box.type === key);
				if (_box) {
					_box.value = value;
					_boxCollections[_box.i] = _box;
				}
			});
			setBoxCollection(_boxCollections);
		}
	}, [highlights]);

	return (
		<Styled.MainWrapper hasHighlights={hasHighlights} hasLogo={hasLogo} data-cy="highlights-section">
			{!isLoading ? (
				<>
					<Styled.Title element="h2">{t('highlights-title')}</Styled.Title>
					{hasHighlights ? (
						<Styled.HighlightsBox>
							<HighlightsCollection boxCollection={boxCollection} />
						</Styled.HighlightsBox>
					) : (
						<EmptyHighlights hasLogo={hasLogo} />
					)}
				</>
			) : null}
		</Styled.MainWrapper>
	);
};

const memorizedHighlights: FC<HighlightsProps> = memo(Highlights);

export { memorizedHighlights as Highlights };
