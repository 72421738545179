import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useShowHoverOptions } from 'hooks/useShowHoverOptions';
import { elementId } from './constants';
import { ImgWithHoverProps } from './types';
import { StyledDropzone } from './components/StyledDropzone';
import { InputWrapper } from './components/InputWrapper';

import { RatioImg, HoverOptions, EditButton, RelativeDiv, ImageInputContainer } from './ImageInputs.styles';

export const ImgWithHover = ({ inputId }: ImgWithHoverProps) => {
	const { t } = useTranslation();
	const { setValue, watch } = useFormContext();
	const image = watch(inputId);

	const { showHoverOptions } = useShowHoverOptions({
		elementId,
		hoverEnabled: image.length > 0,
	});

	return (
		<InputWrapper inputId={inputId} areMultipleImg={false}>
			{image.length < 1 ? (
				<ImageInputContainer data-cy="image-container">
					<StyledDropzone />
				</ImageInputContainer>
			) : (
				<RelativeDiv id={elementId}>
					<RatioImg src={image[0].url} showHoverOptions={showHoverOptions} data-cy="event-img" />
					{showHoverOptions && (
						<HoverOptions>
							<EditButton text={t('button-edit')} category="primary" data-cy="edit-img" />
							<EditButton
								text={t('button-delete')}
								category="tertiary"
								onClick={(e) => {
									e.stopPropagation();
									setValue(inputId, []);
								}}
								data-cy="delete-img"
							/>
						</HoverOptions>
					)}
				</RelativeDiv>
			)}
		</InputWrapper>
	);
};
