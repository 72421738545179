import { FC, memo } from 'react';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { useTranslation } from 'react-i18next';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { useChart } from 'hooks/useChart/useChart';
import { customXTick, toUsFormat } from 'utils/utils';
import { common } from 'theme';
import { CustomTooltip } from './CustomTooltip';
import { NetSalesProps } from './types';
import { NET_SALES } from '../constants';
import { AdjustTimeline } from '../AdjustTimeline';

import * as Styled from './NetSales.styles';

const NetSales: FC<NetSalesProps> = () => {
	const { t } = useTranslation();
	const { dataFrame, setDisplay, displayWindow } = useChart(NET_SALES);

	const customYTick = (amount: number) => '$' + amount;
	const framePayout = toUsFormat(dataFrame?.reduce((acc, date) => (acc += date.payout), 0) || 0);

	return (
		<>
			<Styled.Container>
				<Styled.Text>
					{t('generic-payout')}
					<Styled.Amount>{framePayout}</Styled.Amount>
				</Styled.Text>
				<AdjustTimeline setDisplay={setDisplay} displayWindow={displayWindow} />
			</Styled.Container>
			<FlexContainer width="100%" height="350px" flexDirection="column" alignItems="center">
				<Styled.ResponsiveContainer>
					<AreaChart
						width={500}
						height={400}
						data={dataFrame}
						margin={{
							top: 10,
							right: 30,
							left: 10,
							bottom: 0,
						}}
					>
						<defs>
							<linearGradient id="colorFill" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor={common.colors.green8} stopOpacity={0.8} />
								<stop offset="95%" stopColor={common.colors.green8} stopOpacity={0} />
							</linearGradient>
						</defs>
						<CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
						<XAxis dataKey="date" tickFormatter={customXTick} />
						<YAxis dataKey="payout" tickFormatter={customYTick} minTickGap={0} />
						{/* eslint-disable-next-line react/prop-types */}
						<Tooltip content={(props) => <CustomTooltip key={props.label} {...props} data={dataFrame} />} />
						<Area type="monotone" dataKey="payout" stroke={common.colors.green9} fill="url(#colorFill)" />
					</AreaChart>
				</Styled.ResponsiveContainer>
			</FlexContainer>
		</>
	);
};

const memorizedNetSales: FC<NetSalesProps> = memo(NetSales);

export { memorizedNetSales as NetSales };
