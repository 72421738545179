import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Color } from 'react-color-palette';

import { SelectProps } from './types';
import { sendGAEvent } from 'utils/utils';
import { PaletteModal } from 'features/common/components/Colors/PaletteModal';
import { Icon } from '../Icon/Icon';
import { M } from '../Icon/utils';

import { Arrow } from 'features/CreateEvent/components/EventSummary/EventSummary.styles';
import {
	Container,
	OptionStyled,
	ColorBox,
	ContainerColor,
	ContainerFont,
	Wrapper,
	Text,
	WrapperInfo,
	Image,
} from './SelectFont.styles';

const SelectFont = ({ title, onChange, titleOptions, text, image }: SelectProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const { watch } = useFormContext();
	const initialData = watch(`typoSection.fontStyle.${title}`);
	const [paletteIsOpen, setPaletteIsOpen] = useState(false);

	let data;
	if (initialData && initialData.color) data = { colorSelected: initialData.color };

	const handleMutationData = (newData: Color) => {
		onChange(`${title}.color`, newData.hex);
		setPaletteIsOpen(false);
	};

	const handleOpenModal = () => {
		setPaletteIsOpen(true);
		sendGAEvent({ category: 'WebPage', action: `palette ${title}` });
	};
	return (
		<Container>
			<Wrapper>
				<ContainerColor onClick={handleOpenModal}>
					<ColorBox className={data?.colorSelected ? 'data' : 'none'} color={data?.colorSelected} data-cy={title}>
						{!data?.colorSelected ? <Icon iconName="plus" size={M} /> : <Icon iconName="pencilColor" />}
					</ColorBox>
				</ContainerColor>
				{paletteIsOpen && (
					<PaletteModal currentColor={''} handleChange={handleMutationData} onClose={() => setPaletteIsOpen(false)} />
				)}
				<ContainerFont onClick={() => setIsOpen(!isOpen)}>
					<OptionStyled>{titleOptions}</OptionStyled>
					<Arrow className={isOpen ? 'active' : 'inactive'} iconName="arrowDown" />
				</ContainerFont>
			</Wrapper>
			{isOpen && (
				<WrapperInfo>
					<Text>{text}</Text>
					<Image src={image}></Image>
				</WrapperInfo>
			)}
		</Container>
	);
};

export { SelectFont };
