import { useFormContext } from 'react-hook-form';

import { PickTicketsProps } from 'features/CustomFields/types';

import {
	CheckboxButton,
	CheckboxContainer,
	CheckboxText,
	Label,
	TicketSelectContainer,
	Wrapper,
} from './PickTickets.styles';
import { useTranslation } from 'react-i18next';

export const PickTickets = ({ ticketTypes = [] }: PickTicketsProps) => {
	const { t } = useTranslation();
	const { watch, setValue } = useFormContext();
	const selectedTickets = watch('ticket_types_ids');
	const isChecked = (id: number) => selectedTickets.includes(id);

	const onChange = (id: number) =>
		setValue(
			'ticket_types_ids',
			isChecked(id) ? selectedTickets.filter((ticket: number) => ticket !== id) : [...selectedTickets, id]
		);

	return (
		<Wrapper>
			<Label>{t('customFields-pickTickets')}</Label>
			<TicketSelectContainer>
				{ticketTypes.map((ticket) => (
					<CheckboxContainer key={ticket.ID}>
						<CheckboxButton
							type="checkbox"
							checked={isChecked(ticket.ID)}
							id={`${ticket.ID}`}
							onChange={() => onChange(ticket.ID)}
						/>
						<CheckboxText>{ticket.name}</CheckboxText>
					</CheckboxContainer>
				))}
			</TicketSelectContainer>
		</Wrapper>
	);
};
