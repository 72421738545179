import { ATTENDEES, EVENTS, TOTAL_TICKETS_SOLD } from '../constants';
import { BoxProps } from 'features/common/components/HighlightsCollection/types';

export const highlightsCollection: BoxProps[] = [
	{
		type: TOTAL_TICKETS_SOLD,
		text: 'home-highlights-totalTicketsSold',
		value: 0,
		i: 0,
	},
	{
		type: EVENTS,
		text: 'events',
		value: 55,
		i: 1,
	},
	{
		type: ATTENDEES,
		text: 'home-highlights-attendees',
		value: 0,
		i: 2,
	},
];
