import styled from 'styled-components';

import { Text } from 'features/common/components/Text';
import { FormSide } from 'features/CustomFields/CustomFields.styles';
export { CheckboxButton } from 'features/common/components/CheckboxButton/CheckBoxButton.styles';

export const Wrapper = styled(FormSide)`
	background-color: ${({ theme }) => theme.colors.grey4};
	padding: 46px 0px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		background-color: transparent;
		padding: 18px;
	}
`;

export const Label = styled(Text)`
	font-size: 15px;
	padding-left: 46px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-left: 0;
		line-height: 24px;
	}
`;

export const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const TicketSelectContainer = styled.div`
	padding-left: 46px;
	margin-top: 32px;
	gap: 10px;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-left: 0;
		margin-top: 27px;
	}
`;

export const CheckboxText = styled(Text)`
	font-size: 16px;
	font-weight: 500;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 24px;
	}
`;
