import { TFunction } from 'react-i18next';
import { number, object } from 'yup';

import { FetchedMedia, FieldProps, NewImg, UploadedMedia } from './types';

export const defaultValues: FieldProps = {
	eventName: '',
	mediaContent: {
		eventImage: [],
		media: [],
		bannerHeight: 425,
		bannerHeightMobile: 425,
		topPosition: 400,
		topPositionMobile: 400,
		imagePosition: 'center center',
	},
};

export const validationSchema = (t: TFunction<'translation', undefined>) =>
	object().shape({
		mediaContent: object().shape({
			bannerHeight: number().min(0).required(t('website-media-content-banner-height-required')),
			bannerHeightMobile: number().min(0).required(t('website-media-content-banner-height-mobile-required')),
			topPosition: number().min(0).required(t('website-media-content-top-position-required')),
			topPositionMobile: number().min(0).required(t('website-media-content-top-position-mobile-required')),
		}),
	});

export const isNewImg = (obj: any): obj is NewImg => {
	return typeof obj?.name === 'string' && typeof obj?.base64 === 'string' && typeof obj?.url === 'string';
};

export const isUploadedImg = (obj: any): obj is UploadedMedia => {
	return typeof obj?.url === 'string' && typeof obj?.position === 'number' && typeof obj?.type === 'string';
};

export const isFetchedImg = (obj: any): obj is FetchedMedia => {
	return (
		typeof obj?.ID === 'number' &&
		typeof obj?.url === 'string' &&
		typeof obj?.position === 'number' &&
		typeof obj?.type === 'string'
	);
};

export const isUploadedOrFetched = (obj: any): obj is UploadedMedia | FetchedMedia => {
	return isUploadedImg(obj) || isFetchedImg(obj);
};
