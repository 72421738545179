import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { MainLayout } from 'layouts/MainLayout';
import { EventProps } from 'features/Home/components/Events/types';
import { TicketsSection } from './components/TicketsSection';
import { NavigationTabs } from './components/NavigationTabs';
import { EventInfo } from './components/EventInfo';
import { NetSales } from './components/NetSales';
import { TicketsSold } from './components/TicketsSold';
import { Highlights } from './components/Highlights';
import { PromoCodesSection } from './components/PromoCodesSection';
import { EventWebpageSection } from './components/EventWebpageSection';
import { AffiliatesSection } from './components/AffiliatesSection';
import { ImgContentSection } from './components/ImgContentSection';
import { CustomFieldsSection } from './components/CustomFieldsSection';
import { HIGHLIGHTS, NET_SALES, TICKETS_SOLD } from './components/NavigationTabs/constants';
import { ROUTES } from 'features/routes';
import { getData } from 'services/api';
import { convertUTCToDate } from 'utils/utils';
import { IdParamsType } from 'utils/types';
import { ZERO_TIME } from 'utils/constants';

import { ContentWrapper, Padding, ContainerTabs } from './Dashboard.styles';

const Dashboard = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { id: eventId }: IdParamsType = useParams();
	const [currentTab, SetCurrentTab] = useState<string>(HIGHLIGHTS);

	const { data: event } = useQuery({
		queryKey: [`event-${eventId}`],
		queryFn: (): Promise<EventProps> => getData(`public/ticketing/events/${eventId}`),
	});

	const onClickTab = (selectedTab: string) => {
		SetCurrentTab(selectedTab);
	};

	const onClickTitle = () => push(ROUTES.EDIT_EVENT_PATH.replace(':id', eventId));

	const tab = () => {
		switch (currentTab) {
			case NET_SALES:
				return <NetSales />;
			case TICKETS_SOLD:
				return <TicketsSold />;
			default:
				return <Highlights isDashboard />;
		}
	};

	const [onSale, setOnsale] = useState<string>('');
	const [firstDateStart, setFirstDateStart] = useState<string>('');
	const [firstDateEnd, setFirstDateEnd] = useState<string>('');

	useEffect(() => {
		if (event !== undefined) {
			setFirstDateStart(convertUTCToDate(event.days[0]?.start_date, event.time_zone));
			setFirstDateEnd(convertUTCToDate(event.days[0]?.end_date, event.time_zone));
			setOnsale(convertUTCToDate(event.selling_time, event.time_zone));
		}
	}, [event]);

	const zeroTime = event?.selling_time === ZERO_TIME;

	return (
		<MainLayout>
			<ContentWrapper>
				{event && (
					<EventInfo
						eventTitle={event.name}
						date={moment(firstDateStart).format('L')}
						startTime={moment(firstDateStart).format('LT')}
						endTime={moment(firstDateEnd).format('LT')}
						address={event.street_address}
						onSaleDate={zeroTime ? event.selling_time : `${moment(onSale).format('L')} ${moment(onSale).format('LT')}`}
						onClick={onClickTitle}
					/>
				)}
				<ContainerTabs>
					<NavigationTabs
						activeId={currentTab}
						onClick={onClickTab}
						tabs={[
							{
								id: HIGHLIGHTS,
								name: t('dashboard-navigation-tabs-highlights'),
							},
							{
								id: NET_SALES,
								name: t('dashboard-navigation-tabs-netSales'),
							},
							{
								id: TICKETS_SOLD,
								name: t('dashboard-navigation-tabs-tickets-sold'),
							},
						]}
					/>
					{tab()}
				</ContainerTabs>
				<Padding>
					<TicketsSection />
					{event && <ImgContentSection eventImgCt={Math.min(1, event.images.length)} media={event.media} />}
					{event && <EventWebpageSection id={event.id} isLive={event.live_on_webpage} />}
					<PromoCodesSection />
					<AffiliatesSection />
					{/* TODO: remove comment when backend is ready */}
					{/* <AddOnsSection /> */}
					<CustomFieldsSection />
				</Padding>
			</ContentWrapper>
		</MainLayout>
	);
};

export { Dashboard };
