import styled, { css } from 'styled-components';

import { Text } from 'features/common/components/Text';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import {
	InputContainerStyleProps,
	CalendarWrapperStyleProps,
	StylesProps,
	StyledRadioButtonListProps,
	StyledRadioButtonVariant,
} from './types';
import { common } from 'theme';

const requiredFields = css`
	border: 1px solid ${({ theme }) => theme.colors.greenBlue3};
`;

export const Error = styled(Text)`
	line-height: 16px;
	display: block;
	font-size: 12px;
	font-weight: 400;
	text-transform: none;
	max-width: fit-content;

	&.icon {
		padding-left: 48px;
		margin-bottom: 20px;
	}
`;

export const FormContent = styled.form<StylesProps>``;

export const Input = styled.input`
	width: 100%;
	height: 35px;
	line-height: 20px;
	padding-left: 12px;
	padding-right: 12px;
	box-sizing: border-box;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};

	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&.padding {
		padding-right: 40px;
	}

	&:focus {
		outline: 0;
		width: calc(100% - 1px);
	}
	&.requiredFields {
		${requiredFields}
	}
`;

export const InputContainer = styled.div<InputContainerStyleProps>`
	margin-top: ${({ hasError }) => hasError && '4px'};
	width: ${({ promoCodeForm, type }) => (promoCodeForm && type === 'number' ? '307px' : '100%')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		width: ${({ promoCodeForm, type }) => (promoCodeForm && type === 'number' ? '159px' : '100%')};
	}

	&.relative {
		position: relative;
	}

	&.event-creation {
		border: ${({ theme, hasError }) =>
			hasError ? `0.5px solid ${theme.colors.red}` : `1px solid ${theme.colors.lightBlue}`};
	}

	input,
	textarea,
	select {
		resize: none;
		width: 100%;
		background: ${({ theme }) => theme.colors.blackGrey3};
		color: ${({ theme }) => theme.colors.white};
		font-family: ${({ theme }) => theme.fonts.primary};
		font-size: 16px;
		border-radius: 2px;

		border: ${({ hasError, theme }) => (hasError ? `0.5px solid ${theme.colors.red}` : 'none')};
		&:focus {
			outline: ${({ hasError }) => hasError && '0'};
			border: ${({ hasError, theme }) =>
				hasError ? `0.5px solid ${theme.colors.red}` : `0.5px solid ${theme.colors.lightGrey4}`};
		}

		&:disabled {
			color: ${({ theme }) => theme.colors.lightGrey2};
			background: ${({ theme }) => theme.colors.lightGrey5};
		}
	}
`;

export const Label = styled.label<StyledRadioButtonListProps>`
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${({ theme, variant }) => (variant === 'primary' ? theme.colors.lightGrey2 : theme.colors.white)};
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;

	&.SM {
		font-size: 16px;
		font-weight: 400;
		line-height: 18px;
		color: ${({ theme }) => theme.colors.white};
	}

	&.requiredFields {
		${requiredFields}
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: ${({ variant }) => (variant === 'primary' ? '16px' : '15px')};
		font-weight: ${({ variant }) => (variant === 'primary' ? '400' : '600')};
		line-height: ${({ variant }) => (variant === 'primary' ? '18px' : '22.5px')};
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const LabelContent = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 8px;
`;

export const Select = styled.select`
	border-width: 1px;
	height: 40px;
	line-height: 20px;
	max-width: 100%;
	padding-left: 12px;
	padding-right: 12px;
	width: 256px;

	&:focus {
		outline: 0;
	}
`;

export const Span = styled.span`
	display: block;
	margin-bottom: 8px;
	color: ${({ theme, color }) => color || theme.colors.white};

	> div > span {
		&.top {
			top: 10px;
			height: fit-content;
		}

		&.left {
			@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
				left: -50px;
			}

			@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
				left: -150px;
			}

			left: 10px;
		}

		color: ${({ theme, color }) => color || theme.colors.white};
	}
`;

export const SubtitleSpan = styled(Span)`
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	color: ${({ theme }) => theme.colors.white};
`;

export const Row = styled.div`
	gap: 16px;
	display: flex;
	flex-wrap: wrap;
	padding: 10px 0px;
	align-items: center;
`;

export const Image = styled.img`
	width: 128px;
	height: 128px;
	object-fit: cover;
	border-radius: 8px;
	box-shadow: ${({ theme }) => theme.shadows.imageShadow};
	cursor: pointer;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 80px;
		height: 80px;
	}
`;

export const ImageInputContainer = styled.div`
	width: 128px;
	height: 128px;
	display: flex;
	cursor: pointer;
	padding: 28px 16px;
	border-radius: 8px;
	align-items: center;
	box-sizing: border-box;
	flex-direction: column;
	justify-content: space-around;
	background-color: ${({ theme }) => theme.colors.grey3};
	box-shadow: ${({ theme }) => theme.shadows.imageShadow};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 80px;
		height: 80px;
		padding: 16px;
	}
`;

export const AddImageText = styled(Text)`
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	text-align: center;
	text-transform: none;
	color: ${({ theme }) => theme.colors.white};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
	}
`;

export const AddImageLink = styled(AddImageText)`
	text-align: center;
	text-decoration: underline;
	color: ${({ theme }) => theme.colors.green};

	&.SM {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		&.SM {
			display: flex;
		}
	}
`;

export const IconInputContainer = styled.div`
	gap: 16px;
	display: flex;
	max-width: 100%;
	align-items: center;
`;

export const IconTime = styled(UnstyledIcon)<CalendarWrapperStyleProps>`
	position: absolute;
	top: 8px;
	right: 20px;
	width: 20px;
	height: 20px;

	//TODO: CHECK IF IS NECESSARY
	/* @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
		right: 0px;
	} */

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		right: 20px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
		right: 8px;
	}
`;

export const IconCalendar = styled(UnstyledIcon)<CalendarWrapperStyleProps>`
	position: absolute;
	width: 20px;
	height: 20px;
	top: 8px;
	right: 8px;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		right: 8px;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.xxs}) {
		right: 20px;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		right: 25px;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
		right: 40px;
	}
`;

export const CalendarWrapper = styled.div<CalendarWrapperStyleProps>`
	.react-datepicker-wrapper {
		width: 100%;
	}

	> div > div > input {
		background: ${({ theme }) => theme.colors.blackGrey3};
		border-radius: 2px;
		color: ${({ theme }) => theme.colors.white};
		width: 100%;
		height: 35px;
		padding-left: 10px;
		padding-right: 0px;
		font-family: ${({ theme }) => theme.fonts.primary};
		font-size: 16px;
		box-sizing: border-box;

		&:focus {
			outline: 0;
		}
	}
	&.requiredFields {
		${requiredFields}
	}
`;

export const Calendar = styled.div`
	border-radius: 10px;
	background: ${({ theme }) => theme.colors.grey5};
	box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
	border: none;
	overflow: hidden;

	input:focus + div > i,
	.calendar-popover {
		z-index: 2;
	}

	.react-datepicker {
		border: none;
		z-index: 1;
		background-color: ${({ theme }) => theme.colors.grey5};
	}

	.react-datepicker-wrapper .react-datepicker-wrapper .react-datepicker-ignore-onclickoutside {
		input:focus-visible {
			outline-offset: none;
		}
	}

	.react-datepicker__input-container {
		border-radius: 2px;
		border-style: none;
	}

	.react-datepicker__month {
		background-color: ${({ theme }) => theme.colors.grey5};
	}

	.react-datepicker__triangle {
		border: none;
	}

	.react-datepicker__navigation--previous {
		left: 25px;
		border: solid ${({ theme }) => theme.colors.white};
		border-width: 0 2px 2px 0;
		transform: rotate(135deg);
	}

	.react-datepicker__navigation--next {
		right: 25px;
		border: solid ${({ theme }) => theme.colors.white};
		border-width: 0 2px 2px 0;
		transform: rotate(-45deg);
	}

	.react-datepicker__time-container {
		border: none;
		position: fixed;
		left: 264px;
	}

	.react-datepicker-popper {
		z-index: 1;
	}

	.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
	.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
	.react-datepicker__year-read-view--down-arrow::before,
	.react-datepicker__month-read-view--down-arrow::before,
	.react-datepicker__month-year-read-view--down-arrow::before {
		border: none;
	}

	.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
		border-top: none;
		border-bottom-color: ${({ theme }) => theme.colors.grey};
	}

	.react-datepicker__header {
		background-color: ${({ theme }) => theme.colors.grey5};
		border-bottom: none;
	}

	.react-datepicker-time__header {
		font-family: ${({ theme }) => theme.fonts.primary};
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 145%;
		background-color: ${({ theme }) => theme.colors.grey5};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__time-list-item:hover {
		border-radius: 4px;
		color: ${({ theme }) => theme.colors.grey5};
		background-color: ${({ theme }) => theme.colors.grey5};
	}

	.react-datepicker__time-container .react-datepicker__time {
		background-color: ${({ theme }) => theme.colors.grey5};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.colors.green};
	}

	.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item--selected {
		border-radius: 4px;
		border: 2px solid ${({ theme }) => theme.colors.grey5};
		background-color: ${({ theme }) => theme.colors.green};
		color: ${({ theme }) => theme.colors.grey5};
	}

	.react-datepicker__current-month {
		font-family: ${({ theme }) => theme.fonts.primary};
		color: ${({ theme }) => theme.colors.white};
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 145%;
	}

	.react-datepicker__day-name {
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day {
		border-radius: 100px;
		color: ${({ theme }) => theme.colors.white};
		border: 2px solid ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--selected {
		background-color: ${({ theme }) => theme.colors.green};
		color: ${({ theme }) => theme.colors.white};
		border: 2px solid ${({ theme }) => theme.colors.green};
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--selected:hover {
		border: 2px solid ${({ theme }) => theme.colors.grey5};
	}

	.react-datepicker__time-container {
		border: 2px solid ${({ theme }) => theme.colors.grey};
		border-radius: 0 10px 10px 0;
	}

	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item {
		height: 25px;
	}

	.react-datepicker__day--in-range {
		background-color: ${({ theme }) => theme.colors.green};
		border: 2px solid ${({ theme }) => theme.colors.grey};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day--range-start {
		border-radius: 100%;
		border: 2px solid ${({ theme }) => theme.colors.grey};
	}

	.react-datepicker__day--in-selecting-range {
		background-color: ${({ theme }) => theme.colors.green};
		border: 2px solid ${({ theme }) => theme.colors.grey};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day--keyboard-selected {
		border-radius: 100px;
		background-color: ${({ theme }) => theme.colors.green};
		border: 2px solid ${({ theme }) => theme.colors.green};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day:hover {
		border-radius: 100px;
		background: ${({ theme }) => theme.colors.green};
		border: 2px solid ${({ theme }) => theme.colors.green};
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const Icon = styled(UnstyledIcon)`
	top: -10%;
	right: 10px;
	position: absolute;
	width: 20px;
	height: 20px;
	transform: translateY(50%);
`;

export const CalendarTime = styled(Calendar)`
	.react-datepicker__time-container {
		left: 0;
	}
`;

export const InputTimeContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	//TODO: CHECK IF IS NECESSARY
	/* width: 230px; */
	/* height: 35px; */

	&.margin {
		margin-bottom: 60px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
		height: 100%;
	}
`;

export const TextArea = styled.textarea`
	width: 100%;
	resize: none;
	height: 125px;
	padding: 8px 12px;
	line-height: 20px;
	box-sizing: border-box;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};

	&:focus {
		outline: 0;
		width: calc(100% - 1px);
	}
`;

export const InfoInput = styled(Text)`
	margin-top: 8px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	text-transform: none;
`;

export const ListContainer = styled.div<StyledRadioButtonListProps>`
	overflow: hidden;
	border-radius: ${({ variant }) => (variant === 'primary' ? '6px' : '0px')};
	background-color: ${({ theme, background }) => background ?? theme.colors.blackGrey};
	${({ variant, gap }) =>
		variant === 'secondary' &&
		`
		gap: ${gap || '32px'};
		display: flex;
		flex-direction: column;
	`}
	${({ variant, theme }) =>
		variant === 'tertiary' &&
		`
		display: flex;
		flex-direction: column;
		max-height: 392px;
		overflow-y: scroll;
		background-color: ${theme.colors.black3};
		margin-top: 20px;

		::-webkit-scrollbar {
			display: none;
		}
	`}
	border: ${({ theme, variant }) => (variant === 'primary' ? `0.5px solid ${theme.colors.white}` : '0px')};

	& :last-child {
		border-bottom: 0 !important;
	}
`;

const getOptionContainerBorderBottom = (variant: StyledRadioButtonVariant) => {
	const borderBottom = {
		primary: `0.5px solid ${common.colors.white}`,
		secondary: '0px',
		tertiary: `1px solid ${common.colors.black2}`,
	};
	return borderBottom[variant];
};

const getOptionContainerPadding = (variant: StyledRadioButtonVariant) => {
	const padding = {
		primary: '0px 15px',
		secondary: '0px',
		tertiary: '20px 5px',
	};
	return padding[variant];
};

export const OptionContainer = styled.div<StyledRadioButtonListProps>`
	gap: 20px;
	width: 100%;
	height: 40px;
	display: flex;
	padding: ${({ variant }) => getOptionContainerPadding(variant)};
	align-items: center;
	justify-content: flex-start;
	border-bottom: ${({ variant }) => getOptionContainerBorderBottom(variant)};
`;

export const RadioButtonListContainer = styled.div<StyledRadioButtonListProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 12px;
	${({ variant }) =>
		(variant === 'secondary' || variant === 'tertiary') &&
		`
		gap: 0px;
		margin-bottom: 0px;
	`}
`;

export const RadioButtonLabel = styled.label`
	font-size: 16px;
	font-weight: 500;
	font-family: ${({ theme }) => theme.fonts.primary};
	line-height: 24px;
	cursor: pointer;
`;

export const RelativeDiv = styled.div`
	position: relative;
`;
