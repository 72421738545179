import { FC, memo } from 'react';

import { BoxClassifier } from 'features/common/components/HighlightsCollection/BoxClassifier';
import { BoxProps, HighlightsCollectionProps } from './types';

import { HighlightsContainer } from './HighlightsCollection.styles';

const HighlightsCollection = ({ boxCollection, isDashboard }: HighlightsCollectionProps) => (
	<HighlightsContainer isDashboard={isDashboard}>
		{boxCollection.map(({ type, value, text, viewRoute }: BoxProps) => (
			<BoxClassifier key={type} type={type} value={value} text={text} viewRoute={viewRoute} />
		))}
	</HighlightsContainer>
);

const memorizedHighlightsCollection: FC<HighlightsCollectionProps> = memo(HighlightsCollection);

export { memorizedHighlightsCollection as HighlightsCollection };
