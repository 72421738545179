import { FC, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { ROUTES } from 'features/routes';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { debounce } from 'utils/utils';
import { fanTicketDataGet } from 'services/FanTickets';
import { Header } from 'features/common/components/Header';
import { FlexContainer } from 'features/common/components/FlexContainer/FlexContainer';
import { RTKFilter } from 'features/common/components/Filters';
import { filterOptionRefundableTickets } from 'features/common/components/Filters/constants';
import { Search } from 'features/common/components/Search';
import { TicketTable } from './components/TicketTable';
import { FanTicketData } from 'services/FanTickets/types';
import { IdParamsType } from 'utils/types';
import { MainLayout } from 'layouts/MainLayout';

import { ContentWrapper, Span, BackgroundBlur, RefundButton } from './RefundableTickets.styles';

export const RefundableTickets: FC = () => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const { push } = useHistory();
	const { id: fanId } = useParams<IdParamsType>();
	const [search, setSearch] = useState<string>('');
	const debouncedSearch = debounce(setSearch);
	const selectedTicketsState = useState<Set<number>>(new Set());
	const [selectedTickets] = selectedTicketsState;
	const isVisible = selectedTickets.size > 0;
	const { selectedPeriod, selectedEvents, eventCount } = useAppSelector((state: RootState) => state.refundableTickets);
	const ticketQueryObject = useInfiniteQuery({
		queryKey: ['refunds-ticket-data', selectedPeriod.applied, selectedEvents.applied, search],
		queryFn: ({ pageParam = 1 }): Promise<FanTicketData> =>
			fanTicketDataGet({
				fanId,
				pageParam,
				search,
				selectedPeriod: selectedPeriod.applied,
				appliedEvents: selectedEvents.applied,
			}),
		getNextPageParam: (lastPage, pages) => (lastPage.refundable_tickets.length === 15 ? pages.length + 1 : undefined),
	});
	const fanEmail = ticketQueryObject.data?.pages[0].user_info.email || '';
	const handleRefundClick = () =>
		push(
			ROUTES.REFUND_TICKETS_SUMMARY_PATH.replace(':fanId', fanId).replace(
				':ticketIds',
				Array.from(selectedTickets).join('-')
			)
		);

	return (
		<MainLayout containsStickyEl>
			<Header.TitledHeader title={t('refundableTickets')} subtitle={fanEmail} />
			<ContentWrapper>
				{isLargeScreen && <Span>{t('refundableTickets-select-tickets')}</Span>}
				<FlexContainer justifyContent="space-between">
					<Search onChange={(e) => debouncedSearch(e.target.value)} placeholder={t('refundableTickets-search-by')} />
					<RTKFilter
						selectedPeriod={selectedPeriod}
						selectedEvents={selectedEvents}
						eventCount={eventCount}
						filterOption={filterOptionRefundableTickets}
						page="refundableTickets"
					/>
				</FlexContainer>
				<TicketTable ticketQueryObject={ticketQueryObject} selectedTicketsState={selectedTicketsState} />
			</ContentWrapper>
			{isVisible && (
				<BackgroundBlur className={isVisible ? 'show' : ''}>
					<RefundButton text={t('button-refund')} type="button" onClick={handleRefundClick} />
				</BackgroundBlur>
			)}
		</MainLayout>
	);
};
