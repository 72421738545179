import { TotalTicketSoldProps } from 'features/Analytics/components/AnalyticsSummary/CardsSummary/types';
import { ATTENDEES, TOTAL_TICKETS_SOLD } from 'features/Home/components/constants';
import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { ROUTES } from 'features/routes';
import { Highlights } from 'hooks/useHighlights/types';
import { common } from 'theme';

export const WEEK = 'Past Week';
export const WEEK_VALUE = 'past_week';
export const MONTH = 'Past Month';
export const MONTH_VALUE = 'past_month';
export const YEAR = 'Past Year';
export const YEAR_VALUE = 'past_year';
export const ALL_TIME = 'All Time';
export const ALL_TIME_VALUE = 'all_time';
export const NET_SALES = 'netSales';
export const TICKETS_SOLD = 'ticketsSold';
export const HIGHLIGHTS = 'highlights';
export const YOUR_PAYOUT = 'yourPayout';
export const COMPED = 'comped';
export const ADD_ONS = 'addOns';

export const weeklyDataNetSales = [
	{
		name: 'day1',
		value: 4000,
	},
	{
		name: 'day 2',
		value: 3000,
	},
	{
		name: 'day 3',
		value: 2000,
	},
	{
		name: 'day 4',
		value: 2780,
	},
	{
		name: 'day 5',
		value: 2080,
	},
	{
		name: 'day 6',
		value: 2980,
	},
	{
		name: 'day 7',
		value: 3780,
	},
];

export const monthlyDataNetSales = [
	{
		name: 'Week 1',
		value: 4000,
	},
	{
		name: 'Week 2',
		value: 3000,
	},
	{
		name: 'Week 3',
		value: 2000,
	},
	{
		name: 'Week 4',
		value: 2780,
	},
];

export const yearlyDataNetSales = [
	{
		name: 'Jan',
		value: 4000,
	},
	{
		name: 'Feb',
		value: 3000,
	},
	{
		name: 'Mar',
		value: 2000,
	},
	{
		name: 'Apr',
		value: 2780,
	},
	{
		name: 'May',
		value: 4000,
	},
	{
		name: 'Jun',
		value: 3000,
	},
	{
		name: 'Jul',
		value: 2000,
	},
	{
		name: 'Aug',
		value: 2780,
	},
	{
		name: 'Sep',
		value: 4000,
	},
	{
		name: 'Oct',
		value: 6000,
	},
	{
		name: 'Nov',
		value: 8000,
	},
	{
		name: 'Dec',
		value: 4000,
	},
];

export const allTimeDataNetSales = [
	{
		name: '2013',
		value: 4000,
	},
	{
		name: '2014',
		value: 3000,
	},
	{
		name: '2015',
		value: 2000,
	},
	{
		name: '2016',
		value: 2780,
	},
	{
		name: '2013',
		value: 4000,
	},
	{
		name: '2014',
		value: 3000,
	},
	{
		name: '2015',
		value: 2000,
	},
	{
		name: '2016',
		value: 2780,
	},
	{
		name: '2017',
		value: 4000,
	},
	{
		name: '2018',
		value: 6000,
	},
	{
		name: '2019',
		value: 8000,
	},
	{
		name: '2020',
		value: 6880,
	},
	{
		name: '2021',
		value: 4000,
	},
	{
		name: '2022',
		value: 2000,
	},
	{
		name: '2023',
		value: 4440,
	},
];

export const weeklyDataTicketsSold = [
	{
		name: 'day1',
		adult: 4000,
		child: 2400,
		student: 7400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'day 2',
		adult: 3000,
		child: 1398,
		student: 7400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'day 3',
		adult: 2000,
		child: 9800,
		student: 6400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'day 4',
		adult: 2780,
		child: 3908,
		student: 5400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
];

export const monthlyDataTicketsSold = [
	{
		name: 'Week 1',
		adult: 1890,
		child: 4800,
		student: 4400,
		sold: 100,
		total: 100,
		revenue: '3k',
	},
	{
		name: 'Week 2',
		adult: 1890,
		child: 4800,
		student: 4400,
		sold: 140,
		total: 200,
		revenue: '6k',
	},
	{
		name: 'Week 3',
		adult: 2390,
		child: 3800,
		student: 3400,
		sold: 100,
		total: 200,
		revenue: '7k',
	},
	{
		name: 'Week 4',
		adult: 4000,
		child: 2400,
		student: 7400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
];

export const yearlyDataTicketsSold = [
	{
		name: 'Jan',
		adult: 4000,
		child: 2400,
		student: 7400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Feb',
		adult: 3000,
		child: 1398,
		student: 7400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Mar',
		adult: 2000,
		child: 9800,
		student: 6400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Apr',
		adult: 2780,
		child: 3908,
		student: 5400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'May',
		adult: 2390,
		child: 3800,
		student: 3400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Jun',
		adult: 3490,
		child: 4300,
		student: 5400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Jul',
		adult: 5490,
		child: 3300,
		student: 2400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Aug',
		adult: 1490,
		child: 3300,
		student: 6400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Sep',
		adult: 2490,
		child: 4300,
		student: 1400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Oct',
		adult: 3490,
		child: 4300,
		student: 400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Nov',
		adult: 1490,
		child: 3300,
		student: 6400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
	{
		name: 'Dec',
		adult: 3490,
		child: 4300,
		student: 5400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
];
export const allTimeDataTicketsSold = [
	{
		name: 'All time',
		adult: 2490,
		child: 4300,
		student: 1400,
		sold: 100,
		total: 200,
		revenue: '5k',
	},
];

export const eventHighlightsData: Highlights<string> = {
	total_payout: 0,
	tickets_sold: 30,
	total_attendees: 30,
	tickets_comped: 0,
	sales: [
		{
			date: '2022-03-5',
			payout: 130,
			ticket_types: [
				{
					id: 9631,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9630,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2022-03-30',
			payout: 50,
			ticket_types: [
				{
					id: 9630,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9630,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2022-03-31',
			payout: 100,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2022-04-19',
			payout: 80,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-04-11',
			payout: 10,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 50,
					sold: 30,

					payout: 300,
				},
			],
		},
		{
			date: '2023-03-5',
			payout: 130,
			ticket_types: [
				{
					id: 9631,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9631,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-03-30',
			payout: 50,
			ticket_types: [
				{
					id: 9630,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9630,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-03-31',
			payout: 100,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,

					payout: 300,
				},
			],
		},
		{
			date: '2023-04-19',
			payout: 80,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-04-11',
			payout: 10,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-04-12',
			payout: 30,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-04-15',
			payout: 50,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-04-16',
			payout: 10,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-04-29',
			payout: 100,
			ticket_types: [
				{
					id: 9620,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9620,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
		{
			date: '2023-04-30',
			payout: 30,
			ticket_types: [
				{
					id: 9621,
					name: 'vip',
					amount: 50,
					sold: 50,
					payout: 1500,
				},
				{
					id: 9621,
					name: 'general',
					amount: 100,
					sold: 30,
					payout: 300,
				},
			],
		},
	],
	ticket_types_inventory: [
		{
			ticket_type_id: 9631,
			total_payout: 300,
			name: 'vip',
			sold: 30,
			total_amount: 10,
			holds_amount: 200,
		},
		{
			ticket_type_id: 9630,
			total_payout: 300,
			name: 'vip',
			sold: 30,
			total_amount: 100,
			holds_amount: 200,
		},
		{
			ticket_type_id: 9621,
			total_payout: 300,
			name: 'general',
			sold: 30,
			total_amount: 100,
			holds_amount: 200,
		},
		{
			ticket_type_id: 9620,
			total_payout: 300,
			name: 'general',
			sold: 30,
			total_amount: 100,
			holds_amount: 200,
		},
	],
};

export const highlightsCollection: BoxProps[] = [
	{
		type: YOUR_PAYOUT,
		text: 'dashboard-highlights-yourPayout',
		value: 0,
		i: 0,
	},
	{
		type: TOTAL_TICKETS_SOLD,
		text: 'dashboard-highlights-ticketsSold',
		value: 0,
		i: 1,
	},
	{
		type: COMPED,
		text: 'dashboard-highlights-comped',
		value: 0,
		i: 2,
		viewRoute: ROUTES.COMPED_TICKETS,
	},
	{
		type: ATTENDEES,
		text: 'dashboard-highlights-totalAttendees',
		value: 0,
		i: 3,
		viewRoute: ROUTES.ATTENDEES,
	},
	// TODO: Uncomment after BETA
	// {
	// 	type: ADD_ONS,
	// 	text: 'dashboard-highlights-addOns',
	// 	value: '$1350',
	// },
];

export const totalTicketSoldProps: TotalTicketSoldProps = {
	noGraph: false,
	ticketsSold: 0,
	totalPayout: 0,
	totalAttendees: 0,
};

const { colors } = common;

export const COLORS = [
	colors.green,
	colors.crimson,
	colors.lightBlue2,
	colors.purple1,
	colors.lightBlue3,
	colors.orange3,
	colors.green11,
];
