import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainLayout } from 'layouts/MainLayout';
import { CustomFieldsHeader } from './components/CustomFieldsHeader';
import { QuestionForm } from './components/QuestionForm';
import { CustomFieldFormProps, CustomFieldsProps } from './types';
import { PickTickets } from './components/PickTickets';
import { useCustomFieldsApi } from './useCustomFieldsApi';
import { defaultValues } from './utils';

import { ButtonsContainer, CustomFieldsForm, StyledButton } from './CustomFields.styles';

export const CustomFields = ({ editMode = false }: CustomFieldsProps) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();

	const formMethods = useForm<CustomFieldFormProps>({
		defaultValues,
	});

	const { event, createCustomField, updateCustomField, deleteCustomField } = useCustomFieldsApi(formMethods.reset);

	useEffect(() => {
		if (event) {
			formMethods.setValue('ticket_types_ids', event.ticket_types.map((ticket) => ticket.ID) ?? []);
		}
	}, [event]);

	const handleSubmit = (values: CustomFieldFormProps) => {
		if (editMode) updateCustomField(values);
		else createCustomField(values);
	};

	return (
		<MainLayout>
			<CustomFieldsHeader title={event?.name ?? ''} editMode={!!editMode} onDelete={deleteCustomField} />
			<CustomFieldsForm formMethods={formMethods} onSubmit={handleSubmit}>
				<QuestionForm />
				<PickTickets ticketTypes={event?.ticket_types} />
				<ButtonsContainer>
					<StyledButton text={t('button-cancel')} type="button" onClick={goBack} category="outlined" />
					<StyledButton
						text={t(editMode ? 'save' : 'add')}
						type="submit"
						disabled={formMethods.watch('ticket_types_ids').length == 0}
					/>
				</ButtonsContainer>
			</CustomFieldsForm>
		</MainLayout>
	);
};
