import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { Form } from 'features/common/components/Form';
import { gateOptions, ticketingModeOptions } from '../constants';

import { DropdownContainer, DropdownText, Arrow } from '../EventSummary.styles';

export const AdvancedDetails = () => {
	const { t } = useTranslation();
	const [isOpen, setOpenDropdown] = useState(false);
	const toggleDropdown = () => setOpenDropdown((prevState) => !prevState);

	return (
		<>
			<DropdownContainer type="button" onClick={toggleDropdown} data-cy="advanced-details">
				<DropdownText>{t('advancedDetails-label')}</DropdownText>
				<Arrow className={isOpen ? 'active' : 'inactive'} iconName="arrowDown" />
			</DropdownContainer>

			{isOpen && (
				<FlexContainer flexDirection="column" width="100%">
					<Form.Select
						id="gate_time"
						name="gate_time"
						label={t('createEvent-advancedDetails-gateTime')}
						options={gateOptions}
						withDefaultValue={false}
					/>
					<Form.Input
						id="venue"
						name="venue"
						label={t('createEvent-advancedDetails-venue')}
						placeholder={t('createEvent-advancedDetails-venueCopy')}
					/>
					<Form.Input
						id="support"
						name="support"
						label={t('createEvent-advancedDetails-support')}
						placeholder={t('createEvent-advancedDetails-supportCopy')}
					/>
					<Form.Select
						id="ticketing_mode"
						name="ticketing_mode"
						label={t('createEvent-advancedDetails-ticketFormat')}
						options={ticketingModeOptions}
						withDefaultValue={true}
					/>
				</FlexContainer>
			)}
		</>
	);
};
