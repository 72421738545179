import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { SectionTitle } from 'features/common/components/SectionTitle';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { CircleWithIcon } from 'features/common/components/CircleWithIcon';
import { ROUTES } from 'features/routes';
import { RequiredFieldsModal } from 'features/Embed/components/RequiredFields/RequieredFieldsModal';
import { Divider } from 'features/common/components/Divider';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useToggleEventLive } from 'hooks/useToggleEventLive/useToggleEventLive';
import { EventWebpageProps } from './types';

import { GreenText, LargeText, OvalBtn, Wrapped } from './EventWebpageSection.styles';
import { Container } from 'features/Dashboard/Dashboard.styles';

export const EventWebpageSection = ({ id, isLive }: EventWebpageProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const isLargeScreen = useResponsiveScreen();
	const { toggleChecked, handleToggle, showReqFields, reqFieldsProps } = useToggleEventLive(id, 'webpage', isLive);

	const visitSite = (isLive: boolean) =>
		window.open(`${process.env.REACT_APP_WEBSITE_URL}/${id}${isLive ? '' : '?preview=true'}`, '_blank');

	const handleClick = () => push(generatePath(ROUTES.DASHBOARD_EDIT_WEBSITE_STYLES, { id }));

	return (
		<>
			<SectionTitle
				title={t('dashboard-eventWebpage')}
				buttonLabel={t('dashboard-eventWebpage-editBtn')}
				onClick={handleClick}
				toggleProps={{
					isChecked: toggleChecked,
					handleClick: handleToggle,
					name: 'website-toggle',
					'data-cy': 'website-toggle',
				}}
			/>
			<Wrapped>
				<Container columnOnMobile>
					<FlexContainer alignItems="center" gap="16px">
						<CircleWithIcon height="45px" width="45px" iconName="layout" />
						{toggleChecked ? (
							<FlexContainer flexDirection="column">
								<LargeText
									className="link"
									onClick={() => visitSite(true)}
								>{`${process.env.REACT_APP_WEBSITE_URL}/${id}`}</LargeText>
								<GreenText>{t('dashboard-eventWebpage-isLive')}</GreenText>
							</FlexContainer>
						) : (
							<LargeText>{t('dashboard-eventWebpage-offline')}</LargeText>
						)}
					</FlexContainer>
					<OvalBtn
						category={toggleChecked ? 'primary' : 'quaternary'}
						onClick={() => visitSite(toggleChecked)}
						text={t(`dashboard-eventWebpage-${toggleChecked ? 'view' : 'preview'}`)}
						data-cy="website-link"
					/>
				</Container>
			</Wrapped>
			{!isLargeScreen && <Divider />}
			{showReqFields && <RequiredFieldsModal {...reqFieldsProps} />}
		</>
	);
};
