import { convertUTCToDateBarFormat } from 'utils/utils';

export const elementId = 'imageContainer';

const getTime = (date: string, timezone: string) => convertUTCToDateBarFormat(date, timezone).time;

export const date = (startDate: string, endDate: string) => convertUTCToDateBarFormat(startDate, endDate).date;

export const time = (startDate: string, endDate: string, timezone: string) =>
	getTime(startDate, timezone) + ' - ' + getTime(endDate, timezone);
