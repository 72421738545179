import { DefinedCardNetwork } from 'services/FanTickets/types';

export const matchPaymentLogo = (card: DefinedCardNetwork): string => {
	const cards = {
		mastercard: 'paymentMastercard',
		visa: 'paymentVisa',
		discover: 'paymentDiscover',
		amex: 'paymentAmex',
	};
	return cards[card];
};
