import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'features/common/components/Toggle';
import { ICardComponentProps } from '../../../Embed/components/EventSites/components/types';
import DateComponent from '../../../Embed/components/EventSites/components/DateComponent/DateComponent';
import { ROUTES } from 'features/routes';
import { RequiredFieldsModal } from 'features/Embed/components/RequiredFields/RequieredFieldsModal';
import { useToggleEventLive } from 'hooks/useToggleEventLive';
import { useAppSelector } from 'store/hooks';
import { date, elementId, time } from './utils';

import * as Styled from './EventCardComponent.styles';

export const EventCardComponent = ({ id, name, image, start_date, end_date, time_zone, live }: ICardComponentProps) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { loggedUser } = useAppSelector((state) => state.auth);
	const { toggleChecked, handleToggle, showReqFields, reqFieldsProps } = useToggleEventLive(id, 'widget', live);

	const isKinectair = `${loggedUser?.currentPartner.ID}` === `${process.env.REACT_APP_KINECTAIR_PARTNER_ID}`;
	const kinectairLink = `${process.env.REACT_APP_KINECTAIR_WIDGET}${id}`;

	const handleClick = () => push(ROUTES.DASHBOARD_PATH.replace(':id', id.toString()));

	return (
		<Styled.Card>
			<Styled.TextContainer>
				<Styled.Title>{name}</Styled.Title>
				<DateComponent date={date(start_date, end_date)} time={time(start_date, end_date, time_zone)} />
				{isKinectair ? (
					<Styled.UrlText className="kinectair-margin" onClick={() => window.open(kinectairLink, '_blank')}>
						{kinectairLink}
					</Styled.UrlText>
				) : (
					<Styled.ToggleContainer>
						<Styled.EventStatusText isChecked={toggleChecked}>{t('embed-isLive')}</Styled.EventStatusText>
						<Toggle
							isChecked={toggleChecked}
							name={`${name}${id}`}
							handleChange={handleToggle}
							data-cy={`embed-toggle-${name}`}
						/>
					</Styled.ToggleContainer>
				)}
			</Styled.TextContainer>
			<Styled.ImageContainer id={elementId} onClick={handleClick}>
				<Styled.ImageInfo>
					<Styled.CardImage src={image} alt="card-image" />
				</Styled.ImageInfo>
			</Styled.ImageContainer>
			{showReqFields && <RequiredFieldsModal {...reqFieldsProps} />}
		</Styled.Card>
	);
};
