import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
	AddTickets,
	Carousel,
	ConfirmUpload,
	SelectTemplate,
	TicketCreated,
	TicketDetails,
	TicketImageUpload,
	TicketTemplateImage,
	TicketTypeSelector,
	Success,
} from '..';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { handleGAStep } from 'features/CreateTicket/utils';
import { Number1To9 } from 'features/CreateTicket/types';

export const StepCarousel = () => {
	const { hash } = useLocation();
	const isLargeScreen = useResponsiveScreen();

	useEffect(() => {
		if (hash) handleGAStep(Number(hash.charAt(1)) as Number1To9);
	}, [hash]);

	return (
		<>
			{/* TODO change this after beta launch - Fix Carousel */}
			{isLargeScreen ? (
				<Carousel>
					<AddTickets />
					<TicketDetails />
					<TicketTypeSelector />
					<TicketTemplateImage />
					<SelectTemplate />
					<TicketImageUpload />
					<ConfirmUpload />
					<TicketCreated />
					<Success />
				</Carousel>
			) : (
				<Carousel>
					{hash === '#1' && <AddTickets />}
					{hash === '#2' && <TicketDetails />}
					{hash === '#3' && <TicketTypeSelector />}
					{hash === '#4' && <TicketTemplateImage />}
					{hash === '#5' && <SelectTemplate />}
					{hash === '#6' && <TicketImageUpload />}
					{hash === '#7' && <ConfirmUpload />}
					{hash === '#8' && <TicketCreated />}
					{hash === '#9' && <Success />}
				</Carousel>
			)}
		</>
	);
};
