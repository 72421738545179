import { FlexContainer } from 'features/common/components/FlexContainer';
import { CircleWithIcon } from 'features/common/components/CircleWithIcon';
import { ROUTES } from 'features/routes';
import { SectionContentProps } from './types';
import { EmptySection } from 'features/common/components/EmptySection';

import { Container, Text, BoldText, RotatedIcon, ViewButton } from 'features/Dashboard/Dashboard.styles';

export const SectionContent = ({ affiliateCt, id, pushToPath, t }: SectionContentProps) =>
	affiliateCt > 0 ? (
		<Container>
			<FlexContainer alignItems="center" gap="32px">
				<CircleWithIcon height="45px" width="45px" iconName="link" />
				<BoldText>{t(`dashboard-affiliatesSection-link${affiliateCt !== 1 ? 's' : ''}`, { affiliateCt })}</BoldText>
			</FlexContainer>
			<ViewButton onClick={() => pushToPath(ROUTES.DASHBOARD_VIEW_AFFILIATES, id)}>
				<Text> {t('button-view-all')}</Text>
				<RotatedIcon iconName="arrowDownGreen" />
			</ViewButton>
		</Container>
	) : (
		<EmptySection
			onClick={() => pushToPath(ROUTES.DASHBOARD_NEW_AFFILIATE, id)}
			text={t('dashboard-affiliatesSection-info')}
		/>
	);
