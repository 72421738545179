import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { MainLayout } from 'layouts/MainLayout';
import { getData } from 'services/api';
import { IdParamsType } from 'utils/types';
import { AnalyticsTitle } from '../AnalyticsTitle/AnalyticsTitle';
import TicketInventory from './TicketInventorySection/TicketInventory';
import NetSalesSection from './NetSalesSection/NetSalesSection';
import TicketsSoldSection from './TicketsSoldSection/TicketsSoldSection';
import SummaryCardSection from './SummaryCardSection/SummaryCardSection';
import { Event } from '../../types';

import * as Styled from './AnalyticByEvent.styles';

export const AnalyticByEvent: FC = () => {
	const { id } = useParams<IdParamsType>();

	const { data: event } = useQuery({
		queryKey: ['event', id],
		queryFn: (): Promise<Event> => getData(`public/ticketing/events/${id}`),
	});

	return (
		<MainLayout>
			<AnalyticsTitle subtitle={event?.name} />
			<SummaryCardSection />
			<Styled.ContentWrapper>
				<NetSalesSection />
				<TicketsSoldSection />
				<TicketInventory />
			</Styled.ContentWrapper>
		</MainLayout>
	);
};
